<template>
  <locale-button
    v-if="locale"
    :locale="locale"
    :no-link="true"
    size="md"
    outline
    rounded
    class="locale-button"
  >
    <q-menu
      class="locale-list"
      :offset="[0, 6]"
      auto-close
      fit
    >
      <locale-button
        v-for="item in options"
        :key="item.id"
        :locale="item"
        no-flag
        right-angle
        caps
        full-width
        class="locale-button locale-list__item"
      />
    </q-menu>
  </locale-button>
</template>

<script setup lang="ts">
import LocaleButton from 'src/components/locale/LocaleButton.vue'
import { useLocaleSwitcher } from 'src/composables/useLocaleSwitcher'

const { locale, options } = useLocaleSwitcher()
</script>

<style lang="scss" scoped>
.locale-list {
  &__item {
    display: flex;
  }
}

.locale-button {
  --px: 12px !important;
  --gap: 6px !important;
}
</style>
