<template>
  <modal-aside
    ref="dialogRef"
    class="auth-modal"
    position="right"
    :head="t('translate.account')"
    @hide="onHide"
  >
    <div class="auth-modal__inner">
      <auth-widget
        :initial-social-provider="socialProvider"
        :initial-social-provider-data="socialProviderData"
        @success="onSuccess"
      />
    </div>
  </modal-aside>
</template>

<script setup lang="ts">
import { type LoginSocialProviderResponse, SocialProvider } from 'src/types'
import AuthWidget from 'src/components/auth/widget/AuthWidget.vue'
import ModalAside from 'src/components/modal/ModalAside.vue'
import { ref } from 'vue'
import { useI18n } from 'src/composables/useI18n'
import { useEventBus } from 'src/composables/eventBus'
import { onUnmounted } from 'vue'

const { t } = useI18n()

const eventBus = useEventBus()

const dialogRef = ref<InstanceType<typeof ModalAside> | null>(null)

const socialProvider = ref<SocialProvider | undefined>(undefined)

const socialProviderData = ref<LoginSocialProviderResponse | undefined>(undefined)

eventBus.on('googleOneTapLoginApprove', onGoogleOneTapLoginApprove)

onUnmounted(() => {
  eventBus.off('googleOneTapLoginApprove', onGoogleOneTapLoginApprove)
})

function onGoogleOneTapLoginApprove(data: LoginSocialProviderResponse) {
  socialProvider.value = SocialProvider.GOOGLE
  socialProviderData.value = data
  dialogRef.value?.show()
}

function onHide() {
  socialProvider.value = undefined
  socialProviderData.value = undefined
}

function onSuccess() {
  dialogRef.value?.hide()
}
</script>

<style scoped lang="scss">
.auth-modal {
  &__inner {
    position: relative;
  }
}
</style>
