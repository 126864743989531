import type { RegistrationApprovePayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthRegistrationApproveMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: RegistrationApprovePayload) => api.auth.registrationApprove(payload)
  })
}
