<template>
  <nav class="hidden">
    <a
      v-for="item in links"
      :key="item.href"
      :href="item.href"
    >
      {{ item.name }}
    </a>
  </nav>
</template>

<script lang="ts" setup>
import { useCategoryTree } from 'src/composables/catalog/categoryTree'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import type { CategoryTreeData } from 'src/models/catalog/categoryTree'
import { CategoryTree } from 'src/models/catalog/categoryTree'
import type { Submenu } from 'src/models/menu/submenu'
import { useSubmenu } from 'src/composables/menu/submenu'
import { useNormalizeUrl } from 'src/composables/utils'
import RouterService from 'src/services/router.service'
import { useI18n } from 'src/composables/useI18n'

export type Item = {
  name: string
  href: string
}

const props = defineProps<{
  submenu?: Submenu
  categories?: CategoryTreeData
}>()

const router = useRouter()

const normalizeUrl = useNormalizeUrl()

const { locale } = useI18n()

const submenu = useSubmenu(() => props.submenu)

const categoryTree = useCategoryTree(() => props.categories)

const links = computed<Item[]>(() => {
  const items: Item[] = []

  for (let { name, url } of submenu.items.value) {
    items.push({
      name,
      href: router.resolve(url).fullPath
    })
  }

  for (let item of CategoryTree.getAllTreeItems(categoryTree.value)) {
    const { name, url, slug } = item

    const to = url
      ? normalizeUrl(url)
      : RouterService.getRouteLocation('category', { slug, locale: locale.value })

    if (name) {
      items.push({
        name,
        href: router.resolve(to).fullPath
      })
    }
  }

  return items
})
</script>

<style lang="scss" scoped>

</style>
