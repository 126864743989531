<template>
  <app-dialog
    ref="dialogRef"
    :model-value="showPopup"
    size="md"
    no-head
    no-scroll-lock
    class="banner-popup"
    @close="onClose"
  >
    <div class="banner-popup__container">
      <component
        :is="url ? 'router-link' : 'div'"
        :to="url"
        class="banner-popup__element"
        @click="onClose"
      >
        <app-img
          :src="img"
          fetchpriority="high"
          loading="eager"
          class="banner-popup__image"
          fit="cover"
          ratio="1"
        />
      </component>

      <app-button
        class="banner-popup__close"
        round
        outline
        :icon="iconXmark"
        @click="onClose"
      />
    </div>
  </app-dialog>
</template>

<script lang="ts" setup>
import iconXmark from 'assets/icons/light/xmark.svg?raw'
import type { BannerModel } from 'src/models/Banner.model'
import { useSessionStorage, useTimeout } from '@vueuse/core'
import { useNormalizeUrl } from 'src/composables/utils'
import { computed } from 'vue'
import { useQuasar } from 'quasar'

const props = defineProps<{
  banner: BannerModel
}>()

const $q = useQuasar()

const normalizeUrl = useNormalizeUrl()

const dismiss = useSessionStorage('popup_banner_dismiss', false)

const timeout = useTimeout(props.banner.timeAppear * 1000, {
  controls: true,
})

const showPopup = computed(() => timeout.ready.value && !dismiss.value)

const url = computed(() => {
  const { url } = props.banner
  return url ? normalizeUrl(url) : undefined
})

const img = computed(() => {
  const { smImg, lgImg } = props.banner
  return $q.screen.lt.md ? smImg || lgImg : lgImg || smImg
})

function onClose() {
  dismiss.value = true
}
</script>

<style lang="scss" scoped>
.banner-popup {
  &__container {
    position: relative;
    margin: calc(var(--modal-py) * -1) calc(var(--modal-px) * -1);
  }

  &__element {
    display: block;
    position: relative;
    z-index: 1;
  }

  &__close {
    position: absolute !important;
    top: var(--md);
    right: var(--md);
    z-index: 2;
  }
}
</style>
