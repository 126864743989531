<template>
  <div
    :class="['product-card-small', {
      'product-card-small--unavailable': !props.product.inStock,
      'product-card-small--background': props.background
    }]"
  >
    <app-img
      class="product-card-small__image"
      fit="contain"
      ratio="1"
      :src="product.image"
      :to="to"
      :alt="product.name"
      @click="onClick"
    />
    <div class="product-card-small__content">
      <app-link
        class="product-card-small__name"
        :title="product.name"
        :to="to"
        :color="product.inStock ? 'theme' : 'muted'"
        :label="product.name"
        @click="onClick"
      />
      <base-price
        v-if="product.inStock"
        class="product-card-small__price"
        :price="product.price"
        :old-price="product.oldPrice"
        :discount-badge="true"
        :horizontal="true"
      />
      <product-availability-label
        v-else
        :label="product.availabilityLabel"
        :rest="product.rest"
      />
    </div>
    <div class="product-card-small__btn">
      <app-button
        v-if="product.inStock"
        class="product-card-small__action-button"
        color="primary"
        square
        :icon="iconCartShopping"
        :loading="addToCart.isPending.value"
        @click="addToCart.handler(product.baseProduct)"
      />
      <app-button
        v-else
        color="dark"
        class="product-card-small__action-button"
        :icon="iconEnvelope"
        :tooltip="t('product.reportAvailability')"
        square
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import iconEnvelope from 'assets/icons/light/envelope.svg?raw'
import iconCartShopping from 'assets/icons/light/cart-shopping.svg?raw'
import BasePrice from 'src/components/base/BasePrice.vue'
import ProductAvailabilityLabel from 'src/components/product/ProductAvailabilityLabel.vue'
import RouterService from 'src/services/router.service'
import type { Product } from 'src/models/product'
import { useEventBus } from 'src/composables/eventBus'
import { useI18n } from 'src/composables/useI18n'
import { computed } from 'vue'
import { useProductAddToCart } from 'src/composables/product/useProductAddToCart'

const props = defineProps<{
  product: Product
  background?: boolean
}>()

const { t, locale } = useI18n()

const to = computed(() => RouterService.getRouteLocation('product', {
  locale: locale.value,
  slug: props.product.slug
}))

const addToCart = useProductAddToCart()

const eventBus = useEventBus()

function onClick() {
  eventBus.emit('product:click', props.product.baseProduct)
}
</script>

<style lang="scss" scoped>
.product-card-small {
  display: flex;
  gap: var(--sm);

  &--background {
    background: var(--theme-color-bg);
    border-radius: var(--sm);
    padding: var(--sm);
  }

  &__content {
    flex: 1 1 auto;
  }
  &__image {
    width: 60px;
    height: 60px;
    flex: 0 0 auto;
    border-radius: var(--sm);
  }
  &__name {
    @include line-clamp(2);
    line-height: 20px;
    margin-bottom: var(--xs);
  }
  &__action-button {
    flex: 0 0 auto;
  }
  &__price {
    font-size: 16px !important;
  }
  &--unavailable {
    .product__image {
      opacity: 0.35;
    }
  }
}
</style>
