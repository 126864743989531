<template>
  <div class="auth-widget-reset-password">
    <base-form
      :title="t('translate.passwordRecovery')"
      :loading="isPending"
      @submit="onSubmit"
    >
      <transition-group name="slide">
        <div v-if="!codeSent">
          <base-form-item
            :label="t('messages.restorePassword')"
            :errors="$v.username.$errors"
          >
            <base-input-phone
              v-model="username"
              :error="$v.username.$error"
              autofocus
              @blur="$v.username.$touch"
              @complete="onReset"
            />
          </base-form-item>

          <base-form-item class="q-mt-lg">
            <app-button
              type="submit"
              color="primary"
              :label="t('actions.continue')"
              :loading="reset.isPending.value"
              full-width
            />

            <template #after>
              <base-form-helper
                :action="t('actions.loginToAnotherAccount')"
                :label="t('translate.or')"
                position="center"
                @action="emit('login')"
              />
            </template>
          </base-form-item>
        </div>

        <div v-else>
          <div class="row">
            <div class="column q-col-gutter-y-md full-width">
              <base-form-item
                :label="t('inputs.newPassword.label')"
                :errors="$v.password.$errors"
              >
                <base-input-password
                  v-model="fields.password"
                  :placeholder="t('inputs.newPassword.placeholder')"
                  :error="$v.password.$error"
                  :is-new="true"
                  autofocus
                  @blur="$v.password.$touch"
                />
              </base-form-item>

              <base-form-item
                :label="t('inputs.newPasswordConfirmed.label')"
                :errors="$v.confirmedPassword.$errors"
              >
                <base-input-password
                  v-model="fields.confirmedPassword"
                  :placeholder="t('inputs.newPasswordConfirmed.placeholder')"
                  :error="$v.confirmedPassword.$error"
                  :is-new="true"
                  @blur="$v.confirmedPassword.$touch"
                />
              </base-form-item>

              <base-form-item-code
                v-model="fields.phoneCode"
                :code-sent="codeSent"
                :error="$v.phoneCode.$error"
                :errors="$v.phoneCode.$errors"
                @blur="$v.phoneCode.$touch"
              />
            </div>
          </div>

          <base-form-item class="q-mt-lg">
            <app-button
              type="submit"
              color="primary"
              :loading="approve.isPending.value"
              full-width
              :label="t('actions.restorePassword')"
            />

            <template #after>
              <base-form-helper
                :action="t('actions.loginToAnotherAccount')"
                :label="t('translate.or')"
                position="center"
                @action="emit('login')"
              />
            </template>
          </base-form-item>
        </div>
      </transition-group>
    </base-form>
  </div>
</template>

<script setup lang="ts">
import type { ResetPasswordApprovePayload } from 'src/api/modules/auth.module'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import BaseFormHelper from 'src/components/base/BaseFormHelper.vue'
import BaseFormItemCode from 'src/components/base/BaseFormItemCode.vue'
import BaseInputPhone from 'src/components/base/BaseInputPhone.vue'
import BaseInputPassword from 'src/components/base/BaseInputPassword.vue'
import { useI18n } from 'src/composables/useI18n'
import { reactive, computed, ref, toRefs, watch } from 'vue'
import { useVuelidate, useVuelidateValidators } from 'src/composables/vuelidate'
import { useApiAuthResetPasswordMutation, useApiAuthResetPasswordApproveMutation } from 'src/composables/api/auth'
import { useNotify } from 'src/composables'

const props = defineProps<{
  username?: string
}>()

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'login'): void
}>()

const { t } = useI18n()

const { showError } = useNotify()

const validators = useVuelidateValidators()

const username = ref('')

const fields = reactive<ResetPasswordApprovePayload>({
  password: '',
  confirmedPassword: '',
  phoneCode: ''
})

const rules = computed(() => {
  const { required, phone, phoneCode, password, samePassword } = validators

  return {
    username: { required, phone },
    password: { required, password },
    confirmedPassword: { required, samePassword: samePassword(fields.password) },
    phoneCode: { required, phoneCode },
  }
})

const $v = useVuelidate(rules, { username, ...toRefs(fields) }, { $scope: false })

const reset = useApiAuthResetPasswordMutation()

const approve = useApiAuthResetPasswordApproveMutation()

const codeSent = computed(() => reset.data.value)

const isPending = computed(() => {
  return reset.isPending.value || approve.isPending.value
})

watch(() => props.username, (value) => {
  if (value) username.value = value
}, { immediate: true })

const onReset = async () => {
  const isValid = await $v.value.username.$validate()
  if (!isValid) return

  try {
    await reset.mutateAsync({ username: username.value })
  } catch (e) {
    showError(e)
  }
}

const onApprove = async () => {
  const isValid = await $v.value.$validate()
  if (!isValid) return

  try {
    await approve.mutateAsync(fields)
    emit('success')
  } catch (e) {
    showError(e)
  }
}

function onSubmit() {
  if (isPending.value) return
  return codeSent.value ? onApprove() : onReset()
}
</script>
