<template>
  <div class="auth-widget">
    <transition-group name="slide">
      <auth-widget-login
        v-if="tab === Tab.Login"
        :username="username"
        :title="loginTitle"
        :message="loginMessage"
        @success="onSuccess"
        @registration="onRegistration"
        @login-by-social-approve="onLoginBySocialApprove"
        @reset-password="onResetPassword"
      />

      <auth-widget-fast-registration
        v-else-if="tab === Tab.Registration && fastRegistration"
        :username="username"
        :title="registrationTitle"
        :message="registrationMessage"
        @success="onSuccess"
        @login-by-social-approve="onLoginBySocialApprove"
        @login="onLogin"
      />

      <auth-widget-registration
        v-else-if="tab === Tab.Registration"
        :username="username"
        :title="registrationTitle"
        :message="registrationMessage"
        @success="onSuccess"
        @login-by-social-approve="onLoginBySocialApprove"
        @login="onLogin"
      />

      <auth-widget-login-by-social-approve
        v-else-if="tab === Tab.LoginBySocialApprove && socialProvider && socialProviderData"
        :social-provider="socialProvider"
        :social-provider-data="socialProviderData"
        @success="onSuccess"
        @login="onLogin"
      />

      <auth-widget-reset-password
        v-else-if="tab === Tab.ResetPassword"
        :username="username"
        @success="onLogin"
        @login="onLogin"
      />
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import type { SocialProvider } from 'src/types'
import { type LoginSocialProviderResponse } from 'src/types'
import AuthWidgetLogin from './AuthWidgetLogin.vue'
import AuthWidgetRegistration from './AuthWidgetRegistration.vue'
import AuthWidgetFastRegistration from './AuthWidgetFastRegistration.vue'
import AuthWidgetLoginBySocialApprove from './AuthWidgetLoginBySocialApprove.vue'
import AuthWidgetResetPassword from './AuthWidgetResetPassword.vue'
import { ref } from 'vue'
import { watchOnce } from '@vueuse/core'

enum Tab {
  Login,
  LoginBySocialApprove,
  Registration,
  ResetPassword
}

const props = defineProps<{
  loginTitle?: string
  loginMessage?: string
  registrationTitle?: string
  registrationMessage?: string
  fastRegistration?: boolean
  initialSocialProvider?: SocialProvider
  initialSocialProviderData?: LoginSocialProviderResponse
}>()

const emit = defineEmits<{
  'success': []
}>()

const tab = ref<Tab>(Tab.Login)

const username = ref<string | undefined>(undefined)

const socialProvider = ref<SocialProvider | undefined>(undefined)

const socialProviderData = ref<LoginSocialProviderResponse | undefined>(undefined)

watchOnce([
  () => props.initialSocialProvider,
  () => props.initialSocialProviderData
], ([provider, data]) => {
  if (!provider || !data) return
  socialProvider.value = provider
  socialProviderData.value = data
  tab.value = Tab.LoginBySocialApprove
}, { immediate: true })

function onResetPassword(_username?: string) {
  username.value = _username
  tab.value = Tab.ResetPassword
}

function onLogin(_username?: string) {
  username.value = _username
  tab.value = Tab.Login
}

function onRegistration(_username?: string) {
  username.value = _username
  tab.value = Tab.Registration
}

function onLoginBySocialApprove(provider: SocialProvider, data: LoginSocialProviderResponse) {
  username.value = undefined
  socialProvider.value = provider
  socialProviderData.value = data
  tab.value = Tab.LoginBySocialApprove
}

function onSuccess() {
  emit('success')
}
</script>

<style lang="scss" scoped>
.auth-widget {
  position: relative;
}
</style>
