<template>
  <div class="scale">
    <div class="scale__message">
      {{ message }}
    </div>

    <div class="scale-slider">
      <div class="scale-slider__line">
        <div class="scale-slider__line-element" />
        <div class="scale-slider__icon-line">
          <div class="scale-slider__icon">
            <ui-icon :icon="progressIcon" />
          </div>
        </div>
      </div>

      <div :class="['scale-slider__value', 'text-bold', { 'text-primary': isDone }]">
        {{ maxCountDisplay }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import iconCheck from 'assets/icons/light/check.svg?raw'
import iconTruckFast from 'assets/icons/light/truck-fast.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'
import type { Cart } from 'src/models/cart'
import { useFormattedPrice } from 'src/composables/price'

const props = defineProps<{
  cart: Cart
}>()

const { t } = useI18n()

const progress = computed(() => {
  return Math.min((props.cart.totalPrice / props.cart.freeDeliveryPrice), 1) * 100
})

const isDone = computed(() => progress.value === 100)

const countLeft = computed(() => {
  return Math.max(props.cart.freeDeliveryPrice - props.cart.totalPrice, 0)
})

const progressCssVariable = computed(() => `${progress.value}%`)

const progressIcon = computed(() => {
  return isDone.value ? iconCheck : iconTruckFast
})

const message = computed(() => {
  return props.cart.isFreeDelivery
    ? t('messages.youEarnedFreeShipping')
    : t('messages.freeShipping', { n: countLeft.value })
})

const maxCountDisplay = useFormattedPrice(() => props.cart.freeDeliveryPrice)
</script>

<style lang="scss" scoped>
.scale {
  &__message {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
}
.scale-slider {
  --line-size: 6px;
  --dot-size: 26px;
  --progress: v-bind(progressCssVariable);
  display: flex;
  align-items: center;
  gap: var(--sm);
  &__line {
    --translateX: calc((100% - var(--progress)) * -1);
    flex: 1 1 auto;
    height: var(--line-size);
    background-color: var(--theme-color-separator);
    transition: background-color var(--trs-1);
    border-radius: calc(var(--line-size) / 2);
    position: relative;
  }
  &__line-element {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    &::before {
      content: '';
      display: block;
      height: 100%;
      border-radius: inherit;
      transition: transform var(--trs-2), background-color var(--trs-1);
      background-color: var(--theme-color-primary);
      transform: translateX(var(--translateX));
    }
  }
  &__icon-line {
    position: absolute;
    width: calc(100% - var(--dot-size));
    height: 100%;
    left: var(--dot-size);
    transform: translateX(var(--translateX));
    transition: transform var(--trs-2);
  }
  &__icon {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: 50%;
    background-color: var(--theme-color-primary);
    color: var(--color-light);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    font-size: 12px;
    top: calc((var(--dot-size) - var(--line-size)) / -2);
  }
}
</style>
