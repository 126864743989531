<template>
  <base-no-data
    :title="t('messages.noResults')"
  />
</template>

<script lang="ts" setup>
import BaseNoData from 'src/components/base/BaseNoData.vue'
import { useI18n } from 'src/composables/useI18n'

const { t } = useI18n()
</script>

<style lang="scss" scoped>

</style>
