<template>
  <AppButton
    class="social-provider-auth-button"
    outline
    :label="t('SocialProviderAuthButton.text', { provider: socialProvider.title })"
    :loading="loading"
  >
    <template #prepend>
      <component
        :is="socialProvider.icon"
        class="social-provider-auth-button__icon"
      />
    </template>
  </AppButton>
</template>

<script lang="ts" setup>
import type { SocialProviderEntity } from 'src/types'
import { useI18n } from 'src/composables/useI18n'

defineProps<{
  socialProvider: SocialProviderEntity
  loading?: boolean
}>()

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.social-provider-auth-button {
  --py: 0 !important;

  &__icon {
    width: 26px;
  }
}
</style>
