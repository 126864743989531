<template>
  <div class="auth-widget-login-by-social">
    <social-provider-auth-button
      v-for="socialProvider in socialProviders"
      :key="socialProvider.id"
      :social-provider="socialProvider"
      :loading="isPending === socialProvider.id"
      class="auth-widget-login-by-social__item"
      @click="loginHandler(socialProvider.id)"
    />
  </div>
</template>

<script lang="ts" setup>
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'
import SocialProviderAuthButton from 'src/components/social/SocialProviderAuthButton.vue'
import {
  useLogin, useLoginSocialProvider, useSocialProviders,
  useApiAuthLoginBySocialProviderMutation
} from 'src/composables'
import { ref } from 'vue'

const emit = defineEmits<{
  success: []
  needApprove: [provider: SocialProvider, data: LoginSocialProviderResponse]
}>()

const socialProviders = useSocialProviders()

const isPending = ref<SocialProvider | undefined>(undefined)

const loginSocialProvider = useLoginSocialProvider()

const login = useLogin()

const loginWithSocialProviderMutation = useApiAuthLoginBySocialProviderMutation()

async function loginHandler(provider: SocialProvider) {
  if (isPending.value) return

  isPending.value = provider

  try {
    const data = await loginSocialProvider(provider)

    try {
      const { tokenSet } = await loginWithSocialProviderMutation.mutateAsync({ provider, ...data })
      if (tokenSet) {
        await login(tokenSet)
        emit('success')
      } else {
        emit('needApprove', provider, data)
      }
    } catch {
      emit('needApprove', provider, data)
    }
  } finally {
    isPending.value = undefined
  }
}
</script>

<style lang="scss" scoped>
.auth-widget-login-by-social {
  display: flex;
  flex-direction: column;
  gap: var(--md);

  &__item {
    width: 100%;
  }
}
</style>
