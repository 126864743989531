import { uid } from 'quasar'
import { useModel } from 'src/composables/model'
import { useQuery } from '@tanstack/vue-query'
import { useApi } from 'src/composables'
import { computed, ref, watch, watchEffect } from 'vue'
import type { SearchResponseCategory } from 'src/models/catalog/search'
import { SearchResponse, SearchCategoryResponse } from 'src/models/catalog/search'
import type { SearchParams, SearchCategoryParams } from 'src/api/modules/catalog.module'
import type { TabList } from 'src/types/tabs'
import { syncRef } from '@vueuse/core'
import { useEventBus } from 'src/composables/eventBus'
import { queryKeys } from 'src/api'
import { useQueryIsEnabled } from '../query/queryIsEnabled'
import { useI18n } from 'src/composables/useI18n'

export function useSearch(
  options?: { enabled?: Parameters<typeof useQueryIsEnabled>[0] }
) {
  const DEFAULT_TAB_ID = uid()
  const api = useApi()
  const queryIsEnabled = useQueryIsEnabled(options?.enabled)
  const { t, locale } = useI18n()
  const eventBus = useEventBus()

  const searchText = ref('')
  const page = ref(1)

  const searchParams = computed<SearchParams>(() => ({
    searchText: searchText.value
  }))

  const searchQueryIsEnabled = computed(() => {
    return queryIsEnabled.value && !!searchText.value
  })

  const searchQuery = useQuery({
    queryKey: queryKeys.catalog.search(searchParams, locale).queryKey,
    queryFn: async ({ queryKey }) => {
      const params = queryKey[2].params

      const searchText = params.searchText

      eventBus.emit('search:request', searchText)

      try {
        return await api.catalog.search(params)
      } catch (error) {
        eventBus.emit('search:notFound', searchText)
        throw error
      }
    },
    enabled: searchQueryIsEnabled,
    refetchOnWindowFocus: false
  })

  const searchData = useModel(SearchResponse, searchQuery.data)

  const selectedTab = ref<SearchResponseCategory['id'] | typeof DEFAULT_TAB_ID>(DEFAULT_TAB_ID)
  const selectedCategoryId = ref<SearchResponseCategory['id'] | null>(null)

  syncRef(selectedTab, selectedCategoryId, {
    transform: {
      ltr: left => left === DEFAULT_TAB_ID ? null : left,
      rtl: right => right ?? DEFAULT_TAB_ID
    }
  })

  const selectedCategory = computed(() => {
    return searchData.value?.parentCategories.items.find(item => item.id === selectedCategoryId.value) ?? null
  })

  const categoriesTabs = computed<TabList>(() => {
    if (searchData.value?.parentCategories.notEmpty) {
      const tabAll = {
        id: DEFAULT_TAB_ID,
        label: t('translate.allResults'),
        badge: searchData.value.totalResults
      }

      const results = searchData.value?.parentCategories.items.map(item => ({
        id: item.id,
        label: item.name ?? '',
        badge: item.itemsCount
      })) ?? []

      return [tabAll, ...results]
    }

    return []
  })

  const searchCategoryParams = computed<SearchCategoryParams>(() => ({
    searchText: searchText.value,
    categoryId: selectedCategoryId.value ?? '',
    page: page.value
  }))

  const searchCategoryQueryIsEnabled = computed(() => {
    return searchQueryIsEnabled.value && !!selectedCategoryId.value
  })

  const searchCategoryQuery = useQuery({
    queryKey: queryKeys.catalog.searchCategory(searchCategoryParams, locale).queryKey,
    queryFn: ({ queryKey }) => {
      const { params } = queryKey[2]
      return api.catalog.searchCategory(params)
    },
    enabled: searchCategoryQueryIsEnabled,
    refetchOnWindowFocus: false
  })

  const searchCategoryData = useModel(SearchCategoryResponse, searchCategoryQuery.data)

  const searchIsPending = computed(() => {
    return searchQuery.isFetching.value
  })

  const showCategoryResults = computed(() => {
    const data = searchCategoryData.value
    return !!data?.products.notEmpty && data.category?.id === selectedCategoryId.value
  })

  const showCategoryControls = computed(() => {
    return showCategoryResults.value && !!searchCategoryData.value?.hasControls
  })

  const isCategoryResultsLoading = computed(() => {
    return searchCategoryQuery.isFetching.value && showCategoryResults.value
  })

  const showResults = ref(false)

  const hasResults = computed(() => {
    return !searchQuery.isError.value && searchData.value?.hasResults
  })

  const showTabs = computed(() => {
    return hasResults.value && !searchData.value?.singleCategoryResult
  })

  watchEffect(() => {
    if (searchText.value) {
      if (showResults.value) return
      if ((searchQuery.isSuccess.value || searchQuery.isError.value) && !searchQuery.isFetching.value) {
        showResults.value = true
      }
    } else {
      showResults.value = false
    }
  }, { flush: 'post' })

  watch(() => searchCategoryQuery.isError.value, (value) => {
    if (value) {
      selectedCategoryId.value = null
      page.value = 1
    }
  })

  watch(searchText, (value) => {
    page.value = 1
    if (!value) {
      selectedCategoryId.value = null
    }
  })

  watch(selectedCategoryId, () => {
    page.value = 1
  })

  watch(searchData, (value) => {
    if (value?.singleCategoryResult) {
      selectedCategoryId.value = value.singleCategoryResult.id
    }
  }, { immediate: true })

  const changePage = (newPage?: number) => {
    page.value = newPage ?? 1
  }

  return {
    showResults,
    searchText,
    searchQuery,
    searchData,
    searchIsPending,
    searchCategoryQuery,
    searchCategoryData,
    selectedCategoryId,
    selectedCategory,
    isCategoryResultsLoading,
    showCategoryResults,
    showCategoryControls,
    showTabs,
    categoriesTabs,
    selectedTab,
    hasResults,
    changePage
  }
}
