import type { ResetPasswordPayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthResetPasswordMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: ResetPasswordPayload) => api.auth.resetPassword(payload)
  })
}
