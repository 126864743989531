<template>
  <content-block class="checkout" :loading="loading">
    <auth-widget
      v-if="!isAuth"
      fast-registration
      :login-message="t('messages.letsCreateOrder')"
      :registration-message="t('messages.letsCreateOrder')"
    />

    <cart-checkout-form
      v-else
      class="checkout__form"
      :cart="cart"
      @success="emit('success')"
      @select-delivery="value => emit('selectDelivery', value)"
      @select-payment-method="value => emit('selectPaymentMethod', value)"
    />
  </content-block>
</template>

<script lang="ts" setup>
import type { DeliveryTechnology } from 'src/models/delivery'
import type { Cart } from 'src/models/cart'
import type { PaymentMethod } from 'src/models/payment'
import AuthWidget from 'src/components/auth/widget/AuthWidget.vue'
import CartCheckoutForm from './CartCheckoutForm.vue'
import ContentBlock from 'src/components/ContentBlock.vue'
import { useAccount } from 'src/composables/account'
import { useI18n } from 'src/composables/useI18n'

defineProps<{
  cart: Cart
  loading?: boolean
}>()

const emit = defineEmits<{
  success: [],
  selectDelivery: [DeliveryTechnology | undefined],
  selectPaymentMethod: [PaymentMethod | undefined]
}>()

const { t } = useI18n()

const { isAuth } = useAccount()
</script>

<style lang="scss" scoped>
.checkout {
  --checkout-px: 0px;
  --checkout-py: 0px;

  padding: var(--checkout-py) var(--checkout-px);

  &__form {
    --py: var(--checkout-py) !important;
    --px: var(--checkout-px) !important;
    margin: calc(var(--py) * -1) calc(var(--px) * -1);
  }
}
</style>
