<template>
  <div class="auth-widget-registration">
    <base-form
      :title="title ?? t('messages.niceToMeetYou')"
      :message="message ?? t('messages.letsCreateAccount')"
      :loading="isPending"
      @submit="onSubmit"
    >
      <transition-group name="slide">
        <div v-if="!codeSent">
          <div class="row">
            <div class="column q-col-gutter-y-md full-width">
              <base-form-item
                :errors="v$.phone.$errors"
                :label="t('inputs.phone.label')"
              >
                <base-input-phone
                  v-model="state.phone"
                  :error="v$.phone.$error"
                  username
                  @blur="v$.phone.$touch"
                  @complete="userExists.execute"
                />
              </base-form-item>

              <base-form-item-preset
                v-model="state.firstName"
                preset="firstName"
                :error="v$.firstName.$error"
                :errors="v$.firstName.$errors"
                @blur="v$.firstName.$touch"
              />

              <base-form-item-preset
                v-model="state.lastName"
                preset="lastName"
                :error="v$.lastName.$error"
                :errors="v$.lastName.$errors"
                @blur="v$.lastName.$touch"
              />

              <base-form-item-preset
                v-model="state.email"
                preset="email"
                :error="v$.email.$error"
                :errors="v$.email.$errors"
                @blur="v$.email.$touch"
              />
            </div>
          </div>

          <base-form-item class="q-mt-lg">
            <app-button
              type="submit"
              color="primary"
              :loading="isPending"
              full-width
              :label="t('actions.continue')"
            />

            <template #after>
              <base-form-helper
                :label="t('questions.alreadyRegistered')"
                :action="t('actions.login')"
                @action="emit('login')"
              />
            </template>
          </base-form-item>

          <base-text-separator :text="t('translate.or')" class="q-my-md" />

          <auth-widget-login-by-social
            @success="emit('success')"
            @need-approve="(provider, data) => emit('loginBySocialApprove', provider, data)"
          />
        </div>

        <div v-else>
          <base-form-item-code
            v-model="state.phoneCode"
            :code-sent="codeSent"
            :error="v$.phoneCode.$error"
            :errors="v$.phoneCode.$errors"
            autofocus
            @blur="v$.phoneCode.$touch"
            @complete="onSubmit"
          />

          <app-button
            class="q-mt-lg"
            type="submit"
            color="primary"
            :loading="isPending"
            full-width
            :label="t('actions.registration')"
          />
        </div>
      </transition-group>
    </base-form>
  </div>
</template>

<script setup lang="ts">
import type { FastRegistrationPayload, FastRegistrationApprovePayload } from 'src/api/modules/auth.module'
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItemPreset from 'src/components/base/BaseFormItemPreset.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import BaseFormHelper from 'src/components/base/BaseFormHelper.vue'
import BaseFormItemCode from 'src/components/base/BaseFormItemCode.vue'
import BaseInputPhone from 'src/components/base/BaseInputPhone.vue'
import BaseTextSeparator from 'src/components/base/BaseTextSeparator.vue'
import AuthWidgetLoginBySocial from 'src/components/auth/widget/AuthWidgetLoginBySocial.vue'
import { watch, computed, reactive, ref } from 'vue'
import {
  useUserExists, useLogin, useI18n, useNotify, useVuelidate, useVuelidateValidators,
  useApiAuthFastRegistrationMutation, useApiAuthFastRegistrationApproveMutation
} from 'src/composables'

const props = defineProps<{
  username?: string
  title?: string
  message?: string
}>()

const emit = defineEmits<{
  'success': []
  'login': [username?: string]
  'loginBySocialApprove': [provider: SocialProvider, data: LoginSocialProviderResponse]
}>()

const { t } = useI18n()

const { showError } = useNotify()

const validators = useVuelidateValidators()

const login = useLogin()

const registrationMutation = useApiAuthFastRegistrationMutation()

const registrationApproveMutation = useApiAuthFastRegistrationApproveMutation()

const state = reactive<{
  [K in keyof Required<FastRegistrationApprovePayload>]: FastRegistrationApprovePayload[K] | undefined
}>({
  firstName: undefined,
  lastName: undefined,
  phone: undefined,
  email: undefined,
  phoneCode: undefined
})

const rules = computed(() => {
  const { required, phone, phoneCode, email, firstName, lastName } = validators

  return {
    firstName: { required, firstName },
    lastName: { required, lastName },
    phone: { required, phone },
    email: { required, email },
    phoneCode: { required, phoneCode }
  }
})

const v$ = useVuelidate(rules, state, { $scope: false })

const codeSent = computed(() => registrationMutation.data.value)

const isPending = ref(false)

const userExists = useUserExists({
  onSuccess: ({ username, isExists }) => {
    if (isExists) emit('login', username)
  }
})

watch(() => props.username, (username) => {
  state.phone = username
}, { immediate: true })

async function registrationHandler() {
  const isFirstNameValid = await v$.value.firstName.$validate()
  const isLastNameValid = await v$.value.lastName.$validate()
  const isPhoneValid = await v$.value.phone.$validate()
  const isValidEmail = await v$.value.email.$validate()
  if (isPending.value || !isFirstNameValid || !isLastNameValid || !isPhoneValid || !isValidEmail) return

  isPending.value = true

  const payload = {
    phone: state.phone,
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email
  } as FastRegistrationPayload

  try {
    await registrationMutation.mutateAsync(payload)
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function registrationApproveHandler() {
  const isValid = await v$.value.$validate()
  if (isPending.value || !isValid) return

  isPending.value = true

  try {
    const tokenSet = await registrationApproveMutation.mutateAsync(state as FastRegistrationApprovePayload)
    await login(tokenSet)
    emit('success')
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function onSubmit() {
  if (!codeSent.value) {
    await registrationHandler()
  } else {
    await registrationApproveHandler()
  }
}
</script>
