<template>
  <app-dialog
    ref="dialogRef"
    class="search"
    size="md"
    mobile-full
  >
    <template #head>
      <search-input
        v-model="searchText"
        class="search__input"
        @submit="onSubmit"
      />
    </template>

    <div v-if="showResults" class="search__wrapper">
      <!-- tabs -->
      <div v-if="showTabs" class="search__tabs">
        <base-tabs
          v-model="selectedTab"
          :tabs="categoriesTabs"
          :separator="true"
        />
      </div>

      <app-content
        v-if="hasResults"
        :loading="searchIsPending"
        class="search__content"
      >
        <!-- 1 -->
        <app-content
          v-if="selectedCategoryId && selectedCategory"
          class="search-category"
        >
          <search-category-head
            class="search-category__head"
            :title="selectedCategory.name"
            :to="RouterService.getRouteLocation('category', { locale, slug: selectedCategory.slug })"
          />

          <app-content :loading="isCategoryResultsLoading" class="search-category__results">
            <template v-if="showCategoryResults">
              <product-card-small
                v-for="product in searchCategoryData?.products.items"
                :key="product.id"
                :product="product"
              />
            </template>

            <template v-else>
              <product-card-small-skeleton
                v-for="item in Math.min(selectedCategory.itemsCount, 12)"
                :key="item"
              />
            </template>
          </app-content>

          <div v-if="showCategoryControls" class="search-category__controls">
            <app-button
              :icon="iconChevronLeft"
              :color="searchCategoryData?.prevPage ? 'muted' : 'light'"
              :disabled="!searchCategoryData?.prevPage"
              square
              @click="changePage(searchCategoryData?.prevPage)"
            />

            <app-button
              :icon="iconChevronRight"
              :color="searchCategoryData?.nextPage ? 'muted' : 'light'"
              :disabled="!searchCategoryData?.nextPage"
              square
              @click="changePage(searchCategoryData?.nextPage)"
            />
          </div>
        </app-content>

        <app-content v-else class="search__groups">
          <search-group
            v-for="group in searchData?.productGroups.items"
            :key="group.id"
            :group="group"
            class="search__group"
            @select="id => selectedCategoryId = id"
          />
        </app-content>
      </app-content>

      <div v-else class="search__no-results">
        <search-not-found />
      </div>
    </div>
  </app-dialog>
</template>

<script lang="ts" setup>
import iconChevronRight from 'assets/icons/light/chevron-right.svg?raw'
import iconChevronLeft from 'assets/icons/light/chevron-left.svg?raw'
import AppDialog from 'src/components/ui/AppDialog.vue'
import BaseTabs from 'src/components/base/BaseTabs.vue'
import SearchNotFound from 'src/components/search/SearchNotFound.vue'
import ProductCardSmall from 'src/components/product/card/ProductCardSmall.vue'
import ProductCardSmallSkeleton from 'src/components/product/card/ProductCardSmallSkeleton.vue'
import SearchCategoryHead from 'src/components/search/SearchCategoryHead.vue'
import SearchGroup from 'src/components/search/SearchGroup.vue'
import SearchInput from 'src/components/search/SearchInput.vue'
import { useSearch } from 'src/composables/catalog/search'
import RouterService from 'src/services/router.service'
import { ref } from 'vue'
import { useI18n } from 'src/composables/useI18n'

const { locale } = useI18n()

const dialogRef = ref<InstanceType<typeof AppDialog> | null>(null)

const {
  showResults,
  searchText,
  searchData,
  searchIsPending,
  searchCategoryData,
  selectedCategoryId,
  selectedCategory,
  isCategoryResultsLoading,
  showCategoryResults,
  showCategoryControls,
  showTabs,
  categoriesTabs,
  selectedTab,
  hasResults,
  changePage
} = useSearch()

const onSubmit = () => {
  dialogRef.value?.dialog?.hide()
}
</script>

<style lang="scss" scoped>
.search {
  --modal-inner-pt: 0px !important;
  &__head {
    margin-bottom: var(--md);
  }
  &__tabs {
    --px: var(--modal-px);
    margin: 0 calc(var(--px) * -1);
    padding: 0 var(--px);
    position: sticky;
    overflow: hidden;
    background-color: var(--theme-color-bg);
    transition: background-color var(--trs-1);
    z-index: 2;
    top: 60px;
  }
  &__input {
    flex: 1 1 auto;
  }
  &__content {
    position: relative;
    z-index: 1;
    padding-top: var(--modal-py);
  }
  &__groups {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--modal-py);
  }
}

.search-category {
  position: relative;
  &__head {
    margin-bottom: var(--lg);
  }
  &__controls {
    display: flex;
    justify-content: center;
    gap: var(--sm);
    margin-top: var(--lg);
  }
  &__results {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--md) 0;
  }
}
</style>
