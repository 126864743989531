<template>
  <footer :class="['footer', 'theme-dark', { 'footer--dark': isDark }]">
    <app-container class="footer__container">
      <div class="footer__top">
        <app-logo class="footer__logo" />
      </div>

      <app-separator class="footer__separator" />

      <div class="footer__center">
        <div v-if="groups.length" class="footer__nav">
          <div v-for="group in groups" :key="group.id" class="footer-group">
            <div class="footer-group__title text-h5">
              {{ group.name }}
            </div>

            <ul class="footer-group__list">
              <li v-for="item in group.items" :key="item.id" class="footer-group__item">
                <app-link :to="item.to" :label="item.name" />
              </li>
            </ul>
          </div>
        </div>

        <site-contacts-component
          v-if="contacts"
          v-bind="contacts"
          class="footer__contacts"
        />

        <div class="footer__payment-methods">
          <div class="text-h5">{{ t('translate.weAcceptPayment') }}</div>

          <payment-methods />
        </div>

        <!-- contact -->
        <div v-if="socials.length" class="footer__socials">
          <div class="footer-socials">
            <app-social
              v-for="social in socials"
              :key="social.id"
              :icon="social.icon"
              :href="social.url"
              target="_blank"
              rel="noopener"
            />
          </div>
        </div>
      </div>

      <template v-if="footer.description">
        <app-separator />

        <div class="footer__bottom text-body2 text-muted">
          <p>{{ footer.description }}</p>
        </div>
      </template>
    </app-container>
  </footer>
</template>

<script setup lang="ts">
import PaymentMethods from 'src/components/PaymentMethods.vue'
import SiteContactsComponent from 'src/components/SiteContacts.vue'
import { computed } from 'vue'
import { useDark } from 'src/composables/dark'
import { useI18n } from 'src/composables/useI18n'
import type { Footer, FooterGroup, FooterGroupItem, FooterSocialItem } from 'src/models/footer'
import type { SiteContacts } from 'src/models/site/contacts'
import { useNormalizeUrl } from 'src/composables/utils'

type ComputedGroup = {
  id: FooterGroup['id'],
  name: string
  items: ComputedGroupItem[]
}

type ComputedGroupItem = {
  id: FooterGroupItem['id'],
  name: string
  to: string
}

type ComputedSocialItem = {
  id: FooterSocialItem['id'],
  icon: string,
  url: string
}

const props = defineProps<{
  footer: Footer
  contacts?: SiteContacts
}>()

const  { t } = useI18n()

const { isDark } = useDark()

const normalizeUrl = useNormalizeUrl()

const groups = computed<ComputedGroup[]>(() => {
  return props.footer.group.map((group) => {
    const items: ComputedGroupItem[] = group.urls.map((item) => ({
      id: item.id,
      name: item.name,
      to: (item.url ? normalizeUrl(item.url) : undefined)
    }))
    .filter((item): item is ComputedGroupItem => !!item.name && !!item.to)

    return {
      id: group.id,
      name: group.title,
      items
    }
  })
  .filter((group): group is ComputedGroup => !!group.name && !!group.items.length)
})

const socials = computed<ComputedSocialItem[]>(() => {
  return props.footer.social.filter(
    (item): item is ComputedSocialItem => !!item.icon && !!item.url
  )
})
</script>

<style scoped lang="scss">
.footer {
  --group-py: var(--lg);
  color: var(--theme-dark-color);
  background-color: var(--theme-dark-color-bg);
  border-radius: var(--md) var(--md) 0 0;
  transition: background-color var(--trs-1);

  &--dark {
    background-color: #272727;
  }

  &__logo {
    width: 140px;
    color: var(--theme-color-primary);
    transition: color var(--trs-1);
  }

  &__top {
    padding: var(--group-py) 0;
  }

  &__center {
    padding: var(--group-py) 0;
    display: grid;
    grid-template-columns: 6fr 3fr 3fr;
    gap: var(--group-py) var(--grid-gutter);
  }

  &__bottom {
    padding: var(--group-py) 0;
  }

  &__nav {
    grid-column: 1/2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--group-py) var(--grid-gutter);
  }
  &__contacts {
    grid-column: 2/3;
  }

  &__payment-methods {
    grid-column: 2/4;
    grid-row: 2/3;
  }

  &__socials {
    grid-column: 3/4;
  }

  @media (max-width: $breakpoint-lg-max) {
    &__center {
      padding: var(--group-py) 0;
      display: grid;
      grid-template-columns: 7fr 5fr;
    }

    &__payment-methods {
      grid-column: 1/2;
    }

    &__socials {
      grid-column: 2/3;
    }
  }

  @media (max-width: $breakpoint-sm-max) {
    &__center {
      grid-template-columns: 1fr;
    }

    &__nav, &__contacts {
      grid-column: 1/2;
    }

    &__payment-methods {
      grid-row: 4/5;
    }

    &__socials {
      grid-column: 1/2;
    }
  }

  @media (max-width: $breakpoint-xs-max) {
    &__nav {
      grid-template-columns: repeat(2, 1fr);
    }

    &__logo {
      width: 120px;
    }
  }
}

.footer-group {
  &__item {
    &:not(:last-child) {
      margin-bottom: var(--sm);
    }
  }
}

.footer-socials {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;

  &__skeleton {
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }

  @media (max-width: $breakpoint-lg-max) {
    justify-content: flex-start;
  }
}
</style>
