<template>
  <div class="checkout-form">
    <div class="checkout-form__steps">
      <checkout-step-component
        v-for="(step, id, i) in steps"
        :key="id"
        :title="step.title"
        :index="i"
        :edit="currentStep === id"
        :done="currentStepIndex > i"
        :preview="step.preview"
        :loading="step.loading"
        class="checkout-form__step"
        @edit="currentStep = id"
      >
        <template v-if="id === 'personal'">
          <div class="row">
            <div class="column q-col-gutter-y-md full-width">
              <base-form-item-preset
                v-model="fields.firstName"
                preset="firstName"
                :error="$v.firstName.$error"
                :errors="$v.firstName.$errors"
              />

              <base-form-item-preset
                v-model="fields.lastName"
                preset="lastName"
                :error="$v.lastName.$error"
                :errors="$v.lastName.$errors"
              />

              <base-form-item-preset
                v-model="fields.secondName"
                preset="secondName"
                :error="$v.secondName.$error"
                :errors="$v.secondName.$errors"
              />
            </div>
          </div>

          <app-button
            class="q-mt-lg"
            color="primary"
            full-width
            :label="t('actions.continue')"
            @click="step.onNext"
          />
        </template>

        <template v-else-if="id === 'delivery'">
          <template v-if="isManualDeliverySelection">
            <app-button
              v-if="userDeliveryAddressList.notEmpty.value"
              class="q-mb-lg"
              :label="t('actions.savedAddress')"
              outline
              full-width
              @click="deliverySelectionType = 'saved'"
            />

            <delivery-address-selection
              :ref="el => (deliveryAddressSelectionRef = el as DeliveryAddressSelectionRef)"
              v-model="fields.manualDeliveryAddress"
            />
          </template>

          <template v-else>
            <base-form-item :label="t('translate.deliveryAddress')" :errors="$v.delivery.id.$errors">
              <select-user-delivery-address
                v-model="fields.userDeliveryAddress"
                :addresses="userDeliveryAddressList.items.value"
                :error="$v.delivery.id.$error"
              />
            </base-form-item>

            <app-button
              class="q-mt-lg"
              :label="t('actions.anotherAddress')"
              outline
              full-width
              @click="deliverySelectionType = 'manual'"
            />
          </template>

          <base-form-item class="q-mt-lg">
            <app-checkbox
              v-model="fields.selfPickUp"
              :label="t('translate.IAmTheRecipient')"
            />
          </base-form-item>

          <div v-if="!fields.selfPickUp" class="overflow-hidden q-mt-lg">
            <div class="column q-gutter-y-md">
              <base-form-item
                :label="t('inputs.recipientPhone.label')"
                :errors="$v.recipientPhone.$errors"
              >
                <base-input-phone
                  v-model="fields.recipientPhone"
                  :error="$v.recipientPhone.$error"
                  @blur="$v.recipientPhone.$touch"
                />
              </base-form-item>

              <base-form-item
                :label="t('inputs.recipientFirstName.label')"
                :errors="$v.recipientFirstName.$errors"
              >
                <app-input
                  v-model="fields.recipientFirstName"
                  :error="$v.recipientFirstName.$error"
                  :placeholder="t('inputs.recipientFirstName.placeholder')"
                  @blur="$v.recipientFirstName.$touch"
                />
              </base-form-item>

              <base-form-item
                :label="t('inputs.recipientLastName.label')"
                :errors="$v.recipientLastName.$errors"
              >
                <app-input
                  v-model="fields.recipientLastName"
                  :error="$v.recipientLastName.$error"
                  :placeholder="t('inputs.recipientLastName.placeholder')"
                  @blur="$v.recipientLastName.$touch"
                />
              </base-form-item>

              <base-form-item
                v-if="isCourierDelivery"
                :label="t('messages.deliveryNeedsSecondName')"
                :errors="$v.recipientSecondName.$errors"
              >
                <app-input
                  v-model="fields.recipientSecondName"
                  :error="$v.recipientSecondName.$error"
                  :placeholder="t('inputs.recipientSecondName.placeholder')"
                  @blur="$v.recipientSecondName.$touch"
                />
              </base-form-item>
            </div>
          </div>

          <app-button
            class="q-mt-lg"
            color="primary"
            full-width
            :label="t('actions.continue')"
            @click="step.onNext"
          />
        </template>

        <template v-else-if="id === 'payment'">
          <base-form-item
            :label="t('translate.paymentMethod')"
            :errors="$v.paymentMethod.$errors"
          >
            <app-radio
              v-for="paymentMethod of cartPaymentsQuery.data.value"
              :key="paymentMethod.id"
              v-model="fields.paymentMethod"
              :val="paymentMethod.id"
              :label="paymentMethod.title ?? undefined"
            />
          </base-form-item>

          <app-button
            class="q-mt-lg"
            color="primary"
            full-width
            :label="t('actions.continue')"
            @click="step.onNext"
          />
        </template>

        <template v-else>
          <div class="row">
            <div class="column q-col-gutter-y-lg full-width">
              <cart-apply-bonuses
                v-if="!cart.usedPromocode && cartAvailableBonusesQuery.data.value"
                :available-bonuses="cartAvailableBonusesQuery.data.value"
                :used-bonuses="cart.usedBonuses"
              />

              <cart-apply-promocode
                v-if="!cart.usedBonuses"
                :used-promocode="cart.usedPromocode"
              />

              <base-form-item>
                <app-input
                  v-model="fields.comment"
                  :placeholder="t('inputs.checkoutComment.placeholder')"
                  autogrow
                  counter
                />
              </base-form-item>

              <div class="row">
                <div class="column q-col-gutter-y-sm full-width">
                  <base-form-item v-if="isOnlinePayment">
                    <app-checkbox
                      v-model="fields.doNotCallBack"
                      :label="t('messages.doNotCallBack')"
                    />
                  </base-form-item>

                  <base-form-item>
                    <app-checkbox
                      v-model="fields.personalDataConsent"
                      :label="t('messages.personalDataConsent')"
                    />
                  </base-form-item>
                </div>
              </div>
            </div>
          </div>

          <app-button
            class="q-mt-lg"
            color="primary"
            full-width
            :label="t('actions.checkoutApprove')"
            :loading="isApproveLoading"
            :disabled="!fields.personalDataConsent"
            @click="onApprove"
          />
        </template>
      </checkout-step-component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import DeliveryAddressSelection, { type Props as DeliveryAddressSelectionProps } from 'src/components/delivery/DeliveryAddressSelection.vue'
import SelectUserDeliveryAddress, { type Props as SelectUserDeliveryAddressProps } from 'src/components/form/SelectUserDeliveryAddress.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import BaseFormItemPreset from 'src/components/base/BaseFormItemPreset.vue'
import BaseInputPhone from 'src/components/base/BaseInputPhone.vue'
import CartApplyPromocode from './CartApplyPromocode.vue'
import CartApplyBonuses from './CartApplyBonuses.vue'
import CheckoutStepComponent from 'src/components/cart/CheckoutStep.vue'
import type { DeliveryTechnology } from 'src/models/delivery'
import type { Cart } from 'src/models/cart'
import { useEventBus } from 'src/composables/eventBus'
import { useI18n } from 'src/composables/useI18n'
import { useCartAvailableBonusesQuery, useCartPaymentsQuery } from 'src/composables/cart/queries'
import { computed, reactive, ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { ONLINE_PAYMENT_ID, TRANSPORT_COMPANY_ID } from 'src/config'
import { useAccount } from 'src/composables/account'
import { useVuelidateValidators } from 'src/composables/vuelidate'
import { useNotify } from 'src/composables'
import { useCartApproveMutation } from 'src/composables/cart/mutations'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import type { Nullable } from 'src/types'
import type { PaymentMethod } from 'src/models/payment'
import type { CartApprovePayload } from 'src/api/modules/cart.module'
import { useDeliveryTechnologiesQuery } from 'src/composables/delivery/queries'
import { watchEffect } from 'vue'
import RouterService from 'src/services/router.service'
import { formatPhoneNumber } from 'src/utils/strings'
import { useApiAccountGetDeliveryAddressesQuery } from 'src/composables'
import { UserDeliveryAddressModel } from 'src/models/account/deliveryAddresses'
import { useList } from 'src/composables/list'
import { onBeforeMount } from 'vue'

type DeliveryAddressSelectionRef = Nullable<InstanceType<typeof DeliveryAddressSelection>>

const STEPS = ['personal', 'delivery', 'payment', 'approve'] as const

const props = defineProps<{
  cart: Cart
}>()

const emit = defineEmits<{
  'success': []
  'selectDelivery': [value: DeliveryTechnology | undefined]
  'selectPaymentMethod': [value: PaymentMethod | undefined]
}>()

const eventBus = useEventBus()

const router = useRouter()

const { t, locale } = useI18n()

const account = useAccount()

const validators = useVuelidateValidators()

const { showError } = useNotify()

const cartApproveMutation = useCartApproveMutation()

const currentStep = ref<typeof STEPS[number]>('personal')

const currentStepIndex = computed(() => STEPS.findIndex(step => currentStep.value === step))

const deliveryAddressSelectionRef = ref<DeliveryAddressSelectionRef>(undefined)

const fields = reactive<{
  firstName: Nullable<string>
  secondName: Nullable<string>
  lastName: Nullable<string>
  userDeliveryAddress: SelectUserDeliveryAddressProps['modelValue']
  manualDeliveryAddress: DeliveryAddressSelectionProps['modelValue']
  selfPickUp: boolean
  recipientPhone: Nullable<string>
  recipientFirstName: Nullable<string>
  recipientSecondName: Nullable<string>
  recipientLastName: Nullable<string>
  comment: Nullable<string>
  doNotCallBack: boolean
  paymentMethod: Nullable<PaymentMethod['id']>
  personalDataConsent: boolean
}>({
  firstName: undefined,
  secondName: undefined,
  lastName: undefined,
  userDeliveryAddress: undefined,
  manualDeliveryAddress: undefined,
  selfPickUp: true,
  recipientPhone: undefined,
  recipientFirstName: undefined,
  recipientSecondName: undefined,
  recipientLastName: undefined,
  paymentMethod: undefined,
  comment: undefined,
  doNotCallBack: true,
  personalDataConsent: true
})

const isApproveLoading = ref(false)

const deliverySelectionType = ref<'saved' | 'manual'>('saved')

const userDeliveryAddressesQuery = useApiAccountGetDeliveryAddressesQuery({
  enabled: () => currentStep.value === 'delivery'
})

const userDeliveryAddressList = useList(userDeliveryAddressesQuery.data)

const isManualDeliverySelection = computed(() => {
  if (userDeliveryAddressList.isEmpty.value) return true
  return deliverySelectionType.value === 'manual'
})

const deliveryTechnologiesQuery = useDeliveryTechnologiesQuery(TRANSPORT_COMPANY_ID, {
  enabled: () => currentStep.value === 'delivery'
})

const cartAvailableBonusesQuery = useCartAvailableBonusesQuery({
  enabled: () => currentStep.value === 'approve'
})

const cartPaymentsQuery = useCartPaymentsQuery({
  enabled: () => currentStep.value === 'payment'
})

const selectedUserDeliveryAddress = computed(() => {
  const item = userDeliveryAddressList.items.value.find(item => item.id === fields.userDeliveryAddress?.value)
  return item ? new UserDeliveryAddressModel(item) : undefined
})

const selectedDeliveryTechnology = computed(() => {
  const technologyId = isManualDeliverySelection.value
    ? fields?.manualDeliveryAddress?.technology
    : selectedUserDeliveryAddress.value?.technology

  return deliveryTechnologiesQuery.data.value?.find(item => item.id === technologyId)
})

const isCourierDelivery = computed(() => {
  return selectedDeliveryTechnology.value?.key === 'WarehouseDoors'
})

const selectedPaymentMethod = computed(() => {
  return cartPaymentsQuery.data.value?.find(item => item.id === fields.paymentMethod)
})

const isOnlinePayment = computed(() => {
  return selectedPaymentMethod.value?.id === ONLINE_PAYMENT_ID
})

const payload = computed<CartApprovePayload>(() => {
  const {
    firstName,
    secondName,
    lastName,
    manualDeliveryAddress,
    selfPickUp,
    recipientPhone,
    recipientFirstName,
    recipientSecondName,
    recipientLastName,
    paymentMethod,
    doNotCallBack,
    comment
  } = fields

  const delivery = {} as CartApprovePayload['delivery']

  if (isManualDeliverySelection.value) {
    delivery.city = manualDeliveryAddress?.city?.value
    delivery.technology = manualDeliveryAddress?.technology ?? undefined
    if (isCourierDelivery.value) {
      delivery.street = manualDeliveryAddress?.street?.value
      delivery.house = manualDeliveryAddress?.house ?? undefined
      delivery.apartment = manualDeliveryAddress?.apartment ?? undefined
    } else {
      delivery.novaPoshtaWarehouse = manualDeliveryAddress?.novaPoshtaWarehouse?.value
    }
  } else {
    delivery.id = fields.userDeliveryAddress?.value
  }

  const data: CartApprovePayload = {
    phone: undefined,
    firstName: firstName || undefined,
    secondName: secondName || undefined,
    lastName: lastName || undefined,
    email: undefined,
    selfPickUp,
    recipientPhone: (!selfPickUp ? recipientPhone : undefined) ?? undefined,
    recipientFirstName: (!selfPickUp ? recipientFirstName : undefined) ?? undefined,
    recipientSecondName: ((!selfPickUp && isCourierDelivery.value) ? recipientSecondName : undefined) ?? undefined,
    recipientLastName: (!selfPickUp ? recipientLastName : undefined) ?? undefined,
    transportCompany: TRANSPORT_COMPANY_ID,
    delivery,
    paymentMethod: paymentMethod ?? undefined,
    comment: comment || undefined,
    doNotCallBack: isOnlinePayment.value ? doNotCallBack : false,
    promocode: props.cart.usedPromocode,
    bonuses: props.cart.usedBonuses
  }

  return data
})

const rules = computed(() => {
  const { phone, required, firstName, secondName, lastName } = validators
  const { selfPickUp } = payload.value

  return {
    firstName: { required, firstName },
    secondName: { required, secondName },
    lastName: { required, lastName },
    recipientPhone: !selfPickUp ? { required, phone } : {},
    recipientFirstName: !selfPickUp ? { required } : {},
    recipientSecondName: (!selfPickUp && isCourierDelivery.value) ? { required } : {},
    recipientLastName: !selfPickUp ? { required } : {},
    delivery: {
      id: !isManualDeliverySelection.value ? { required } : {}
    },
    paymentMethod: { required }
  }
})

const $v = useVuelidate(rules, payload, { $scope: false })

const validatePersonalStep = async (): Promise<boolean> => {
  const res = await Promise.all([
    $v.value.firstName.$validate(),
    $v.value.secondName.$validate(),
    $v.value.lastName.$validate(),
  ])

  return res.every(Boolean)
}

const validateDeliveryStep = async (): Promise<boolean> => {
  const validateAddressSelection = async (): Promise<boolean> => {
    if (!isManualDeliverySelection.value) return true
    return deliveryAddressSelectionRef.value?.validate() ?? false
  }

  const results = await Promise.all([
    validateAddressSelection(),
    $v.value.recipientPhone.$validate(),
    $v.value.recipientFirstName.$validate(),
    $v.value.recipientSecondName.$validate(),
    $v.value.recipientLastName.$validate(),
    $v.value.delivery.id.$validate()
  ])

  return results.every(Boolean)
}

const validatePaymentStep = async (): Promise<boolean> => {
  return $v.value.paymentMethod.$validate()
}

const onApprove = async () => {
  if (!fields.personalDataConsent) return

  isApproveLoading.value = true

  try {
    const { orderId, paymentUrl } = await cartApproveMutation.mutateAsync(payload.value)

    const successLocation = RouterService.getRouteLocation('checkout.status', {
      locale: locale.value,
      orderId
    })

    if (paymentUrl?.url) {
      window.location.href = paymentUrl.url
    } else {
      emit('success')
      await router.push(successLocation)
    }
  } catch (error) {
    showError(error)
  } finally {
    isApproveLoading.value = false
  }
}

const steps = computed<
  Record<typeof STEPS[number], {
    title: string
    loading?: boolean
    preview?: string
    onNext?: () => void
  }>
>(() => {
  const { firstName, lastName } = fields

  const { city, street, house, apartment, novaPoshtaWarehouse } = fields.manualDeliveryAddress ?? {}

  const personalPreview = `${firstName} ${lastName} / ${account.usernameDisplay.value}`

  const manualDeliveryAddressName = [
    city?.label,
    selectedDeliveryTechnology.value?.name,
    ...(isCourierDelivery.value ? [street?.label, house, apartment] : [novaPoshtaWarehouse?.label])
  ].filter(item => !!item).join(', ')

  const deliveryAddress = isManualDeliverySelection.value
    ? manualDeliveryAddressName
    : selectedUserDeliveryAddress.value?.name

  const deliveryRecipient = !fields.selfPickUp
    ? `${fields.recipientFirstName}${isCourierDelivery.value ? ' ' + fields.recipientSecondName : ''} ${fields.recipientLastName}`
    : undefined

  const deliveryRecipientPhone = !fields.selfPickUp && fields.recipientPhone
    ? formatPhoneNumber(fields.recipientPhone)
    : undefined

  const deliveryPreview = [deliveryAddress, deliveryRecipient, deliveryRecipientPhone]
    .filter(item => !!item)
    .join(' / ')

  const paymentPreview = selectedPaymentMethod.value?.title ?? undefined

  const isDeliveryStepLoading = userDeliveryAddressesQuery.isInitialLoading.value
    || deliveryTechnologiesQuery.isInitialLoading.value

  const isPaymentStepLoading = cartPaymentsQuery.isInitialLoading.value

  const isApproveStepLoading = cartAvailableBonusesQuery.isInitialLoading.value

  return {
    personal: {
      title: t('translate.personalData'),
      preview: personalPreview,
      onNext: async () => {
        const isValid = await validatePersonalStep()
        if (isValid) currentStep.value = 'delivery'
      }
    },
    delivery: {
      title: t('translate.delivery'),
      loading: isDeliveryStepLoading,
      preview: deliveryPreview,
      onNext: async () => {
        const isValid = await validateDeliveryStep()
        if (isValid) currentStep.value = 'payment'
      }
    },
    payment: {
      title: t('translate.payment'),
      preview: paymentPreview,
      loading: isPaymentStepLoading,
      onNext: async () => {
        const isValid = await validatePaymentStep()
        if (isValid) currentStep.value = 'approve'
      }
    },
    approve: {
      title: t('translate.approve'),
      loading: isApproveStepLoading
    }
  }
})

watchEffect(() => {
  const { firstName, lastName, secondName } = account

  if (firstName.value) {
    fields.firstName = firstName.value
  }

  if (lastName.value) {
    fields.lastName = lastName.value
  }

  if (secondName.value) {
    fields.secondName = secondName.value
  }
})

watch(selectedDeliveryTechnology, (value) => {
  emit('selectDelivery', value)
}, { immediate: true })

watch(selectedPaymentMethod, (value) => {
  emit('selectPaymentMethod', value)
}, { immediate: true })

onBeforeMount(() => {
  if (!$v.value.firstName.$invalid && !$v.value.secondName.$invalid && !$v.value.lastName.$invalid) {
    currentStep.value = 'delivery'
  }
})

onMounted(() => {
  eventBus.emit('cart:checkout', props.cart)
})

onBeforeUnmount(() => {
  emit('selectDelivery', undefined)
  emit('selectPaymentMethod', undefined)
})
</script>

<style lang="scss" scoped>
.checkout-form {
  --px: 0px;
  --py: 0px;
  &__step {
    padding: var(--py) var(--px);
    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-color-separator);
      transition: border-color var(--trs-1);
    }
  }
}
</style>
