<template>
  <div class="feedback-widget">
    <button
      type="button"
      class="feedback-widget-button"
    >
      <span class="feedback-widget-button__wrapper">
        <span class="feedback-widget-button__inner">
          <ui-icon
            v-for="link in LINKS"
            :key="link.id"
            ref="buttonIconsRef"
            :icon="link.icon"
            :class="['feedback-widget-button__icon', `feedback-widget-button__icon--${link.id}`]"
          />
        </span>

        <transition name="fade">
          <span v-if="showList" class="feedback-widget-button__close">
            <ui-icon :icon="iconXmark" />
          </span>
        </transition>
      </span>

      <q-menu
        v-model="showList"
        transition-show="jump-up"
        transition-hide="jump-down"
        anchor="top right"
        self="bottom right"
        auto-close
        :offset="[0, 15]"
        class="feedback-widget-menu"
      >
        <template
          v-for="item in LINKS"
          :key="item.id"
        >
          <app-button
            v-if="unref(item.show)"
            :class="['feedback-widget-menu__link', `feedback-widget-menu__link--${item.id}`]"
            tag="a"
            align="left"
            :label="unref(item.label)"
            :href="item.href"
            :target="item.target"
            :icon="item.icon"
            @click="item.onClick"
          />
        </template>
      </q-menu>
    </button>

    <div class="bingc-action-open-passive-form hidden" />
  </div>
</template>

<script lang="ts" setup>
import iconViber from 'assets/icons/brands/viber.svg?raw'
import iconTelegram from 'assets/icons/brands/telegram.svg?raw'
import iconXmark from 'assets/icons/light/xmark.svg?raw'
import iconPhoneArrowDownLeft from 'assets/icons/solid/phone-arrow-down-left.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import { ref } from 'vue'
import { gsap } from 'src/assets/scripts/animation'
import { onUnmounted, watchEffect } from 'vue'
import { useMounted } from '@vueuse/core'
import { computed } from 'vue'
import { unref } from 'vue'
import { useScriptTag } from '@vueuse/core'
import { onMounted } from 'vue'
import { useBinotelGetCall } from 'src/composables/useBinotelGetCall'
import { useI18n } from 'src/composables/useI18n'

declare global {
  interface Window {
    BinotelGetCall: any
  }
}

const { t } = useI18n()

let animation: gsap.core.Timeline | undefined = undefined

const isMounted = useMounted()
const showList = ref(false)
const buttonIconsRef = ref<InstanceType<typeof UiIcon>[] | undefined>(undefined)

const binotelIsLoaded = ref(false)

const binotelGetCall = useBinotelGetCall()

const LINKS = [
  {
    id: 'phone',
    label: computed(() => t('actions.callMeBack')),
    icon: iconPhoneArrowDownLeft,
    show: binotelIsLoaded,
    onClick: binotelGetCall
  },
  {
    id: 'viber',
    label: 'Viber',
    icon: iconViber,
    href: 'viber://chat?number=%2B380930863696',
    show: true
  },
  {
    id: 'telegram',
    label: 'Telegram',
    icon: iconTelegram,
    href: 'https://t.me/Gelius01',
    target: '_blank',
    show: true
  }
]

const createAnimation = (target?: HTMLElement[]) => {
  if (!target) return

  return gsap.timeline({ repeat: -1 })
    .set(target, { autoAlpha: 0 })

    .fromTo(target,
      { translateX: 0, autoAlpha: 1 },
      { translateX: -50, autoAlpha: 0, duration: 1, stagger: { each: 1 } })

    .fromTo(target.slice(1),
      { translateX: 50, autoAlpha: 0 },
      { translateX: 0, autoAlpha: 1, duration: 1, stagger: { each: 1 } }, '<')

    .fromTo(target[0],
      { translateX: 50, autoAlpha: 0 },
      { translateX: 0, autoAlpha: 1, duration: 1 }, '-=1')

    /* .fromTo(target[0],
      { translateX: 50 },
      { translateX: 0, duration: 1 }, '-=1') */
}

watchEffect(() => {
  if (isMounted.value && buttonIconsRef.value?.length) {
    const target = buttonIconsRef.value.map(item => item.$el)
    animation = createAnimation(target)
  }
}, { flush: 'post' })

onUnmounted(() => {
  animation?.kill()
})

const binotel = useScriptTag(
  'https://widgets.binotel.com/getcall/widgets/f5u8kt7przuvi39wicuc.js',
  () => {
    binotelIsLoaded.value = true
  }, {
    immediate: false
  }
)

onMounted(() => {
  setTimeout(() => {
    binotel.load()
  }, 5000)
})
</script>

<style lang="scss" scoped>
.feedback-widget-button {
  --color: var(--theme-color-primary);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  color: var(--color);
  box-shadow: var(--shadow);

  .body--dark & {
    --color: var(--theme-color);
  }

  &::before, &::after {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    background-color: var(--color);
    transition: background-color var(--trs-1);
    animation-name: ripple;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    pointer-events: none;
  }

  &::before {
    animation-duration: 3s;
    animation-delay: 0.5s;
  }

  &::after {
    animation-duration: 3s;
    animation-delay: 0s;
  }

  &__wrapper {
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: 8px solid var(--color);
    transition: border-color var(--trs-1);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 100%;
    z-index: 2;
    background-color: var(--theme-color-light);
    transition: background-color var(--trs-1), border-color var(--trs-1);
    overflow: hidden;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: 3;
    font-size: 24px;
    background-color: var(--theme-color-bg);
  }

  &__icon {
    position: absolute;
    font-size: 32px;
    transition: color var(--trs-1);

    &--phone {
      transform: scale(0.8);
    }
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}
</style>

<style lang="scss">
.bingc-blur-whole-page {
  filter: initial !important;
}

.feedback-widget-menu {
  width: 260px;
  padding: 16px 10px;
  overflow: initial !important;
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: var(--theme-color-bg) transparent transparent transparent;
    transition: border-color var(--trs-1);
    position: absolute;
    bottom: -8px;
    right: 27px;
  }
  &__link {
    --icon-size: 24px !important;
    --gap: 12px !important;
    display: block;
    width: 100%;
    border-radius: none;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &--phone {
      .button__icon {
        transform: scale(0.8);
      }
    }
  }
}
</style>
