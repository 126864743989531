import { useNotify } from 'src/composables'
import RouterService from 'src/services/router.service'
import type { CartItem } from 'src/models/cart/item'
import type { MaybeRefOrGetter } from 'vue'
import { computed, toValue } from 'vue'
import { useCartChangeItemMutation, useCartDeleteItemMutation } from './mutations'
import { useEventBus } from 'src/composables/eventBus'
import { Cart } from 'src/models/cart'
import { useI18n } from 'src/composables/useI18n'
import { formatPrice } from 'src/utils/price'

export function useCartItem(item: MaybeRefOrGetter<CartItem>) {
  const changeItemMutation = useCartChangeItemMutation()

  const deleteItemMutation = useCartDeleteItemMutation()

  const eventBus = useEventBus()

  const { t, locale } = useI18n()

  const { showError } = useNotify()

  const count = computed(() => toValue(item).count)

  const name = computed(() => toValue(item).name)

  const image = computed(() => toValue(item).image)

  const to = computed(() => {
    return RouterService.getRouteLocation('product', { slug: toValue(item).slug, locale: locale.value })
  })

  const discountMessage = computed(() => {
    if (!toValue(item).discount) return undefined

    if (toValue(item).discountType === 'promocode') {
      return t('messages.promocodeDiscount', { count: formatPrice(-toValue(item).discount) })
    }

    return t('messages.usedBonusesCount', { count: formatPrice(-toValue(item).discount) })
  })

  const changeMethod = {
    handler: async (newCount: number) => {
      try {
        const itemValue = toValue(item)
        const { ref, baseProduct } = itemValue

        const newCart = await changeItemMutation.mutateAsync({ ...ref, count: newCount })

        eventBus.emit('cart:update', new Cart(newCart))

        const changeCount = newCount - itemValue.count

        if (changeCount > 0) {
          eventBus.emit('product:addToCart', baseProduct, changeCount)
        } else if (changeCount < 0) {
          eventBus.emit('product:removeFromCart', baseProduct, Math.abs(changeCount))
        }
      } catch (e) {
        showError(e)
      }
    },
    isPending: changeItemMutation.isPending
  }

  const deleteMethod = {
    handler: async () => {
      try {
        const { ref, baseProduct, count } = toValue(item)
        const newCart = await deleteItemMutation.mutateAsync(ref)
        eventBus.emit('cart:update', new Cart(newCart))
        eventBus.emit('product:removeFromCart', baseProduct, count)
      } catch (e) {
        showError(e)
      }
    },
    isPending: deleteItemMutation.isPending
  }

  return { name, image, to, count, discountMessage, changeMethod, deleteMethod }
}
