<template>
  <app-select
    :model-value="modelValue"
    :options="options"
    :error="error"
    @update:model-value="value => emit('update:modelValue', value)"
    @blur="emit('blur')"
  />
</template>

<script lang="ts" setup>
import AppSelect, { type Props as SelectProps, type Emits as SelectEmits } from 'src/components/ui/AppSelect.vue'
import { UserDeliveryAddressModel, type UserDeliveryAddress } from 'src/models/account/deliveryAddresses'
import { watch } from 'vue'
import { computed } from 'vue'

type ExactSelectProps = SelectProps<UserDeliveryAddress['id']>

type ExactSelectEmits = SelectEmits<UserDeliveryAddress['id']>

export type Props = Pick<ExactSelectProps, 'modelValue' | 'error'> & {
  addresses: UserDeliveryAddress[]
}

type Emits = Pick<ExactSelectEmits, 'blur' | 'update:modelValue'>

const props = defineProps<Props>()

const emit = defineEmits<Emits>()

const options = computed<ExactSelectProps['options']>(() => {
  return props.addresses.map(item => {
    const model = new UserDeliveryAddressModel(item)

    return {
      label: model.name,
      value: model.id
    }
  })
})

watch(options, (options) => {
  if (options.length && !props.modelValue) {
    emit('update:modelValue', options[0])
  }
}, { immediate: true })
</script>

<style lang="scss" scoped>

</style>
