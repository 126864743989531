<template>
  <transition name="fade">
    <app-button
      v-if="isVisible"
      :icon="iconCheveronUp"
      color="light"
      shadow
      round
      :size="$q.screen.gt.xs ? 'lg' : 'md'"
      @click="onClick"
    />
  </transition>
</template>

<script lang="ts" setup>
import iconCheveronUp from 'assets/icons/light/chevron-up.svg?raw'
import { useWindowScroll, computedEager } from '@vueuse/core'

const { y } = useWindowScroll()

const isVisible = computedEager(() => {
  return y.value > 100
})

const onClick = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>

</style>
