import type { LoginApprovePayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthLoginApproveMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: LoginApprovePayload) => api.auth.loginApprove(payload)
  })
}
