import type { LoginBySocialProviderApprovePayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthLoginBySocialProviderApproveMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: LoginBySocialProviderApprovePayload) => {
      return api.auth.loginBySocialProviderApprove(payload)
    }
  })
}
