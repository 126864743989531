<template>
  <div class="header-nav">
    <div class="header-nav__wrapper">
      <app-container class="header-nav__container">
        <nav class="header-nav__list">
          <li v-for="(item, index) in items" :key="index" class="header-nav__item">
            <app-link :to="item.url" class="header-nav__link">
              {{ item.name }}
            </app-link>
          </li>
        </nav>
      </app-container>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Submenu } from 'src/models/menu/submenu'
import { useSubmenu } from 'src/composables/menu/submenu'

const props = defineProps<{
  submenu?: Submenu
}>()

const { items } = useSubmenu(() => props.submenu)
</script>

<style scoped lang="scss">
.header-nav {
  --height: 40px;
  border-radius: 0 0 var(--md) var(--md);
  background-color: $primary;
  color: $light;

  &__container {
    height: var(--height);
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    gap: $md;
    overflow: auto;
    @include hidden-scrollbar;
  }

  &__item {
    white-space: nowrap;
  }

  &__link {
    --app-link-color: $light !important;
    --app-link-color-hover: #{rgba($light, 0.75)} !important;
  }

  @media (max-width: $breakpoint-md-max) {
    display: none;
  }
}
</style>
