import type { RegistrationPayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthRegistrationMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: RegistrationPayload) => api.auth.registration(payload)
  })
}
