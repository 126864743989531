<template>
  <div class="mobile-panel theme-dark">
    <app-container class="mobile-panel__container">
      <component
        :is="item.to ? 'router-link' : 'button'"
        v-for="item in list"
        :key="item.id"
        v-ripple
        :class="[
          'mobile-panel-item',
          'mobile-panel__item',
          {
            'mobile-panel-account': item.id === account.id,
            'mobile-panel-account--active': item.id === account.id && isActive
          }
        ]"
        :to="item.to"
        @click="item.onClick"
      >
        <ui-icon v-if="item.icon" :icon="item.icon" class="mobile-panel-item__icon" />
        <span class="mobile-panel-item__label">{{ item.label }}</span>
        <app-badge v-if="item.badge" class="mobile-panel-item__badge">{{ item.badge }}</app-badge>
      </component>
    </app-container>
  </div>
</template>

<script lang="ts" setup>
import UiIcon from 'src/components/ui/UiIcon.vue'
import { computed } from 'vue'
import { useNav } from 'src/composables/nav'
import { useAccountNav } from 'src/composables'
import { useLink } from 'vue-router'
import { useRouteLocation } from 'src/composables/utils/routeLocation'

const { main, menu, cart, account } = useNav()

const { favourites } = useAccountNav()

const list = computed(() => ([
  main.value,
  menu.value,
  cart.value,
  favourites.value,
  account.value
]))

const { isActive } = useLink({ to: useRouteLocation('account') })
</script>

<style lang="scss" scoped>
.mobile-panel {
  background-color: var(--theme-color-bg);
  border-top: 1px solid var(--theme-color-separator);
  padding-bottom: env(safe-area-inset-bottom, 0px);
  transition: background-color var(--trs-1), border-color var(--trs-1);
  &__container {
    display: flex;
  }
  &__item {
    flex: 1 1 20%;
  }
  @media (max-width: $breakpoint-xs-max) {
    &__container {
      padding: 0 !important;
    }
  }
}

.mobile-panel-item {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 var(--app-container-px);
  text-decoration: none;
  color: var(--theme-color);
  transition: color var(--trs-1);
  padding: 6px 0 8px 0;
  &__icon {
    margin-bottom: 4px;
    font-size: 18px;
  }
  &__label {
    font-size: 10px;
    line-height: 1;
  }
  &__badge {
    position: absolute;
    z-index: 2;
    top: 4px;
    left: 50%;
  }
  &.router-link-exact-active {
    color: var(--theme-color-primary);
    .mobile-panel-item__icon {
      font-weight: 700;
    }
  }
}

.mobile-panel-account--active {
  .mobile-panel-item__icon {
    font-weight: 700;
  }
}
</style>
