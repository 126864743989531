<template>
  <modal-aside
    class="cart"
    position="right"
    :model-value="modelValue"
    :head="showMobileCheckout ? t('translate.checkout') : t('translate.cart')"
    :back-button="showMobileCheckout"
    @back="hideCheckout()"
    @hide="onHide()"
    @update:model-value="value => emit('update:modelValue', value)"
  >
    <template v-if="cart.model.value?.notEmpty">
      <keep-alive>
        <checkout
          v-if="showMobileCheckout"
          :cart="cart.model.value"
          :loading="cart.isPending.value"
          class="cart__checkout"
          @success="onCheckoutSuccess"
          @select-delivery="onSelectDelivery"
          @select-payment-method="onSelectPaymentMethod"
        />
      </keep-alive>

      <content-block
        v-if="!showMobileCheckout"
        :loading="cart.isPending.value"
        class="cart__content"
      >
        <div v-for="item in cart.model.value.list.items" :key="item.id" class="cart__item">
          <cart-item :item="item" :show-messages="isCheckout" />
        </div>

        <div
          v-if="promoItems?.products.length || cart.model.value.hasGift"
          class="cart__after"
        >
          <cart-promo-items
            v-if="promoItems?.products.length"
            :description="promoItems.groupInfo.description"
            :url="promoItems.groupInfo.url"
            :products="promoItems.products"
          />

          <cart-item-gift
            v-if="cart.model.value.hasGift"
            :text="cart.model.value.giftInfo"
          />
        </div>
      </content-block>
    </template>

    <empty-cart v-else @action="hideCart()" />

    <template v-if="cart.model.value && !cart.model.value.isEmpty" #bottom>
      <content-block v-if="cart.model.value && !cart.model.value.isEmpty" class="cart-info" :loading="cart.isPending.value">
        <cart-free-shipping-scale
          :cart="cart.model.value"
          class="cart__free-shipping-scale"
        />

        <div class="cart-info__content">
          <div v-if="itemsPrice" class="cart-info__line">
            <div class="text-muted">{{ t('translate.productsPriceTotal', { n: cart.model.value.totalItems }) }}</div>
            <div :class="[isCheckout ? 'cart-info__line-value' : 'cart-total-price']">{{ itemsPrice }}</div>
          </div>

          <div v-if="isCheckout && discountType === 'bonuses'" class="cart-info__line">
            <div class="text-muted">{{ t('messages.usedBonuses') }}</div>
            <div class="cart-info__line-value text-discount">{{ discountFormatted }}</div>
          </div>

          <div v-if="isCheckout && discountType === 'promocode'" class="cart-info__line">
            <div class="text-muted">{{ t('messages.promocodeIsUsed') }}</div>
            <div class="cart-info__line-value">
              <span v-if="cart.model.value.hasGift" class="text-primary">{{ t('translate.gift') }}</span>
              <span v-else class="text-discount">{{ discountFormatted }}</span>
            </div>
          </div>

          <div v-if="isCheckout && !!selectedDelivery && !!selectedPaymentMethod" class="cart-info__line cart-info__delivery">
            <div class="text-muted">{{ t('translate.costOfDelivery') }}</div>
            <delivery-price-component :price="deliveryPrice" />
          </div>

          <div v-if="isCheckout && totalPriceFormatted" class="cart-info__line cart-info__total">
            <div class="text-muted">{{ t('translate.toPay') }}</div>
            <div class="cart-total-price">{{ totalPriceFormatted }}</div>
          </div>
        </div>

        <app-button
          v-if="!isCheckout"
          :label="t('actions.checkout')"
          color="primary"
          class="cart-info__button full-width"
          @click="showCheckout()"
        />
      </content-block>
    </template>

    <template #outside>
      <transition name="fade">
        <keep-alive>
          <cart-modal-checkout
            v-if="cart.model.value && showDesktopCheckout"
            :cart="cart.model.value"
            :loading="cart.isPending.value"
            @select-delivery="onSelectDelivery"
            @select-payment-method="onSelectPaymentMethod"
            @success="onCheckoutSuccess"
            @close="hideCheckout"
          />
        </keep-alive>
      </transition>
    </template>
  </modal-aside>
</template>

<script setup lang="ts">
import type { DeliveryTechnology } from 'src/models/delivery'
import type { PaymentMethod } from 'src/models/payment'
import CartPromoItems from 'src/components/cart/CartPromoItems.vue'
import DeliveryPriceComponent from 'src/components/delivery/DeliveryPrice.vue'
import CartFreeShippingScale from './CartFreeShippingScale.vue'
import CartModalCheckout from 'src/components/cart/CartModalCheckout.vue'
import ContentBlock from 'src/components/ContentBlock.vue'
import EmptyCart from 'src/components/cart/EmptyCart.vue'
import CartItem from 'src/components/cart/CartItem.vue'
import CartItemGift from 'src/components/cart/CartItemGift.vue'
import ModalAside from 'src/components/modal/ModalAside.vue'
import Checkout from 'src/components/cart/Checkout.vue'
import { useCart } from 'src/composables/cart'
import { ref, computed, watch } from 'vue'
import { useQuasar } from 'quasar'
import { useI18n } from 'src/composables/useI18n'
import { isEqual } from 'lodash'
import { useNotify } from 'src/composables'
import { ONLINE_PAYMENT_ID } from 'src/config'
import { useApiCartGetPromoItemsQuery } from 'src/composables'
import { useAccount } from 'src/composables/account'
import { useFormattedPrice } from 'src/composables/price'

type Props = {
  modelValue?: boolean
  checkout?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  'update:modelValue': [value: Props['modelValue']]
  'update:checkout': [value: Props['checkout']]
}>()

const { showInfo } = useNotify()

const { t } = useI18n()

const $q = useQuasar()

const cart = useCart({
  queryIsEnabled: () => props.modelValue
})

const {
  data: promoItems
} = useApiCartGetPromoItemsQuery({
  enabled: () => !!cart.model.value?.notEmpty
})

const selectedDelivery = ref<DeliveryTechnology | undefined>(undefined)

const selectedPaymentMethod = ref<PaymentMethod | undefined>(undefined)

const isOnlinePayment = computed(() => {
  return selectedPaymentMethod.value?.id === ONLINE_PAYMENT_ID
})

const { isAuth, isSeller } = useAccount()

const itemsPrice = useFormattedPrice(() => {
  return cart.model.value?.oldTotalPrice
})

const deliveryPrice = computed<number | undefined>(() => {
  const { isFreeDelivery } = cart.model.value ?? {}
  if (isFreeDelivery && isOnlinePayment.value || (isAuth.value && isSeller.value)) return 0
  return undefined
})

const discountType = computed(() => cart.model.value?.discountType)

const discountFormatted = useFormattedPrice(() => cart.model.value?.discount)

const totalPriceFormatted = useFormattedPrice(() => cart.model.value?.totalPrice)

const isCheckout = computed(() => props.checkout && cart.model.value?.notEmpty)
const isMobileCheckout = computed(() => $q.screen.lt.lg)
const showMobileCheckout = computed(() => isCheckout.value && isMobileCheckout.value)
const showDesktopCheckout = computed(() => isCheckout.value && !isMobileCheckout.value)

/* Notify if cart has changed after authorization */
watch(cart.model, (newCart, oldCart) => {
  if (!newCart || !oldCart) return
  const isEqualCart = isEqual(newCart.composition, oldCart.composition)
  const isUpdatedCart = !newCart.userKey && !!oldCart.userKey && !isEqualCart
  if (isUpdatedCart) showInfo(t('messages.cartUpdated'))
})

const hideCart = () => {
  emit('update:modelValue', false)
}

const hideCheckout = () => {
  emit('update:checkout', false)
}

const showCheckout = () => {
  emit('update:checkout', true)
}

const onCheckoutSuccess = () => {
  hideCart()
}

const onHide = () => {
  hideCheckout()
}

const onSelectDelivery = (value: DeliveryTechnology | undefined) => {
  selectedDelivery.value = value
}

const onSelectPaymentMethod = (value: PaymentMethod | undefined) => {
  selectedPaymentMethod.value = value
}
</script>

<style scoped lang="scss">
.cart {
  &__free-shipping-scale {
    background-color: var(--theme-color-bg);
    transition: background-color var(--trs-1);
    margin-bottom: var(--md);
  }
  &__content {
    margin: calc(var(--modal-aside-py) * -1) calc(var(--modal-aside-px) * -1);
    position: relative;
    z-index: 1;
  }
  &__item {
    padding: var(--modal-aside-py) var(--modal-aside-px);
    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-color-separator);
    }
  }
  &__checkout {
    --checkout-px: var(--modal-aside-px) !important;
    --checkout-py: var(--modal-aside-py) !important;
    margin: calc(var(--modal-aside-py) * -1) calc(var(--modal-aside-px) * -1);
  }
  &__after {
    padding: var(--sm);
    display: flex;
    flex-direction: column;
    gap: var(--sm);
  }
}

.cart-total-price {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
}

.cart-info {
  &__line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    &:not(:last-child) {
      margin-bottom: var(--xs);
    }
  }
  &__line-value {
    font-weight: 700;
    font-size: 18px;
    line-height: calc(var(--typography-body1) * var(--typography-line-height));
  }
  &__delivery {
    margin-top: 2px;
  }
  &__total {
    margin-top: var(--sm);
  }
  &__button {
    margin-top: var(--md);
  }
}
</style>
