<template>
  <div :class="['checkout-step', { 'checkout-step--done': done }]">
    <div class="checkout-step__head">
      <div :class="['checkout-step__dot', { 'checkout-step__dot--done': done }]">
        <ui-icon v-if="done" :icon="iconCheck" />
        <span v-else>{{ index + 1 }}</span>
      </div>

      <div class="checkout-step__title text-h5">{{ title }}</div>

      <div v-if="showPreview" class="checkout-step__preview text-body2 text-muted">
        {{ preview }}
      </div>

      <base-spinner
        v-if="loading"
        class="checkout-step__spinner"
      />

      <app-link
        v-if="showEdit"
        class="checkout-step__edit"
        color="link"
        dashed
        @click="emit('edit')"
      >
        {{ t('actions.change') }}
      </app-link>
    </div>

    <div v-if="showContent" class="checkout-step__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import iconCheck from 'assets/icons/light/check.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import BaseSpinner from 'src/components/base/BaseSpinner.vue'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'

const props = defineProps<{
  title: string
  index: number
  loading?: boolean
  edit?: boolean
  done?: boolean
  preview?: string
}>()

const emit = defineEmits<{
  edit: []
}>()

const { t } = useI18n()

const done = computed(() => props.done && !props.edit)

const showContent = computed(() => props.edit && !props.loading)

const showPreview = computed(() => !!props.preview && done.value)

const showEdit = computed(() => props.done && !props.edit && !props.loading)
</script>

<style lang="scss" scoped>
.checkout-step {
  --dot-size: var(--lg);
  --head-gx: 12px;
  position: relative;
  overflow: hidden;
  &__head {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    gap: 0 var(--head-gx);
  }
  &__spinner {
    width: var(--dot-size) !important;
    height: var(--dot-size) !important;
  }
  &__edit {
    flex: 0 0 auto;
    grid-column: 3/4;
    grid-row: 1/2;
  }
  &__title {
    margin: 0;
    flex: 1 1 auto;
    grid-column: 2/3;
    grid-row: 1/2;
  }
  &__dot {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: 50%;
    background-color: var(--theme-color-muted-light);
    color: var(--color-light);
    transition: color var(--trs-1), background-color var(--trs-1);
    &--done {
      background-color: var(--theme-color-primary);
    }
  }
  &__content {
    padding: var(--lg) 0 0 0;
  }
  &__preview {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
</style>
