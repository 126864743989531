<template>
  <div class="banner-top-header">
    <component
      :is="url ? 'router-link' : 'div'"
      :to="url"
      class="banner-top-header__element"
    >
      <img
        :src="img"
        fetchpriority="high"
        loading="eager"
        class="banner-top-header__image"
        alt="banner"
      >
    </component>
  </div>
</template>

<script lang="ts" setup>
import type { BannerModel } from 'src/models/Banner.model'
import { useNormalizeUrl } from 'src/composables/utils'
import { computed } from 'vue'
import { useQuasar } from 'quasar'

const props = defineProps<{
  banner: BannerModel
}>()

const $q = useQuasar()

const normalizeUrl = useNormalizeUrl()

const url = computed(() => {
  const { url } = props.banner
  return url ? normalizeUrl(url) : undefined
})

const img = computed(() => {
  const { smImg, lgImg } = props.banner
  return $q.screen.lt.md ? smImg || lgImg : lgImg || smImg
})
</script>

<style lang="scss" scoped>
.banner-top-header {
  &__element {
    display: block;
    position: relative;
    height: 32px;
    background-color: var(--theme-color-bg);
    overflow: hidden;
  }

  &__image {
    position: absolute;
    height: 100%;
    max-width: initial;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
