<template>
  <div :class="['layout', { 'layout--no-background': noBackground }]">
    <banner-top-header
      v-if="topHeaderBanner"
      :banner="topHeaderBanner"
    />

    <header-component
      ref="headerRef"
      :outline="headerOutline"
      :class="['layout__header', { 'layout__header--sticky': headerSticky }]"
    />

    <header-nav
      ref="headerNavRef"
      :class="['layout__header-nav']"
      :submenu="siteInfo?.submenu"
    />

    <seo-nav
      :submenu="siteInfo?.submenu"
      :categories="siteInfo?.categories"
    />

    <main class="layout__content">
      <router-view />
    </main>

    <the-footer
      v-if="siteInfo?.footer"
      ref="footerRef"
      :footer="siteInfo.footer"
      :contacts="siteInfo.contacts"
      :class="['layout__footer', { 'layout__footer--border': (noBackground && $q.dark.isActive) }]"
    />

    <!-- NO SSR -->
    <q-no-ssr>
      <the-mobile-panel
        v-if="$q.screen.lt.lg"
        ref="mobilePanelRef"
        class="layout__mobile-panel"
      />
    </q-no-ssr>

    <q-no-ssr>
      <div class="layout__fixed-buttons">
        <the-scroll-up-button class="layout__scroll-up-button" />
        <the-feedback-widget class="layout__feedback" />
      </div>
    </q-no-ssr>

    <q-no-ssr>
      <auth-modal v-model="stateStore.showAuthModal" />
    </q-no-ssr>

    <q-no-ssr>
      <the-cookies-notification />
    </q-no-ssr>

    <q-no-ssr>
      <cart-modal
        v-model="stateStore.isCartOpen"
        v-model:checkout="stateStore.isCheckoutOpen"
      />
    </q-no-ssr>

    <q-no-ssr v-if="popupBanner">
      <banner-popup :banner="popupBanner" />
    </q-no-ssr>
  </div>
</template>

<script setup lang="ts">
import TheCookiesNotification from 'src/components/TheCookiesNotification.vue'
import TheFeedbackWidget from 'src/components/TheFeedbackWidget.vue'
import SeoNav from 'src/components/SeoNav.vue'
import TheScrollUpButton from 'src/components/TheScrollUpButton.vue'
import AuthModal from 'src/components/auth/AuthModal.vue'
import CartModal from 'src/components/cart/CartModal.vue'
import HeaderComponent from 'src/components/header/Header.vue'
import HeaderNav from 'src/components/header/HeaderNav.vue'
import TheFooter from 'src/components/TheFooter.vue'
import TheMobilePanel from 'src/components/TheMobilePanel.vue'
import BannerTopHeader from 'src/components/banner/BannerTopHeader.vue'
import BannerPopup from 'src/components/banner/BannerPopup.vue'
import { useWindowScroll, computedEager } from '@vueuse/core'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useElementBounding } from '@vueuse/core'
import { useStyleTag } from '@vueuse/core'
import { useQuasar } from 'quasar'
import { useStateStore } from 'src/stores/state'
import { useApiSiteGetInfoQuery } from 'src/composables/api/site'
import { usePrefetchStore } from 'src/stores/prefetch'
import { useModel } from 'src/composables/model'
import { BannerModel } from 'src/models/Banner.model'

const $q = useQuasar()

const stateStore = useStateStore()

const { data: siteInfo } = useApiSiteGetInfoQuery()

const topHeaderBanner = useModel(BannerModel, () => siteInfo.value?.banners.topHeaderBanner)

const popupBanner = useModel(BannerModel, () => siteInfo.value?.banners.popupBanner)

/* refs */
const headerRef = ref<InstanceType<typeof HeaderComponent> | null>(null)
const headerNavRef = ref<InstanceType<typeof HeaderNav> | null>(null)
const mobilePanelRef = ref<InstanceType<typeof TheMobilePanel> | null>(null)
const footerRef = ref<InstanceType<typeof TheFooter> | null>(null)
const headerEl = computed<HTMLElement | null>(() => headerRef.value?.$el)
const headerNavEl = computed<HTMLElement | null>(() => headerNavRef.value?.$el)
const mobilePanelEl = computed<HTMLElement | null>(() => mobilePanelRef.value?.$el)
const footerEl = computed<HTMLElement | null>(() => footerRef.value?.$el)
/* boundings */
const headerElBounding = useElementBounding(headerEl)
const headerNavElBounding = useElementBounding(headerNavEl)
const mobilePanelElBounding = useElementBounding(mobilePanelEl)
const footerElBounding = useElementBounding(footerEl)

const route = useRoute()
const windowScroll = useWindowScroll()

const styles = computed(() => (
  `body {
    --computed-header-height: ${headerElBounding.height.value}px;
    --computed-header-nav-height: ${headerNavElBounding.height.value}px;
    --computed-mobile-panel-height: ${mobilePanelElBounding.height.value}px;
    --computed-footer-height: ${footerElBounding.height.value}px;
  }`
))

const headerSticky = computed<boolean>(() => {
  return !route.meta.staticHeader && $q.screen.gt.md
})

const headerOutline = computedEager<boolean>(() => {
  const scrollEnaugth = windowScroll.y.value > headerNavElBounding.height.value
  const rule1 = headerSticky.value && scrollEnaugth
  const rule2 = !!route.meta.staticHeader && $q.screen.lt.lg
  return rule1 || rule2
})

const noBackground = computed<boolean>(() => !!route.meta.noBackground)

useStyleTag(styles)
</script>

<script lang="ts">
import { queryKeys } from 'src/api'

export default {
  async preFetch({ store }) {
    const { queryClient, api, i18n } = usePrefetchStore(store)

    await queryClient.prefetchQuery({
      queryKey: queryKeys.site.getInfo(i18n.global.locale).queryKey,
      queryFn: () => api.site.getInfo()
    })
  }
}
</script>

<style lang="scss" scoped>
.layout {
  --layout-pt: 40px;
  --layout-pb: 40px;
  --layout-content-top: calc(var(--computed-header-height) + var(--computed-header-nav-height) + var(--layout-pt));
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-template-bg);
  transition: background-color var(--trs-1);

  &--no-background {
    background-color: transparent;
  }

  &__header {
    position: relative;
    top: 0;
    z-index: 5;
    flex: 0 0 auto;
    width: 100%;
    &--sticky {
      position: sticky;
    }
  }

  &__header-nav {
    position: relative;
    z-index: 3;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: var(--computed-header-height);
      bottom: 100%;
      left: 0;
      background-color: var(--theme-color-primary);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: var(--layout-pt) 0 var(--layout-pb) 0;
  }

  &__mobile-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }

  &__footer {
    flex: 0 0 auto;
    width: 100%;
    padding-bottom: var(--computed-mobile-panel-height);
    position: relative;
    z-index: 3;
    border-top: 1px solid transparent;
    transition: border-color var(--trs-1), border-radius var(--trs-1);
    &--border {
      border-color: var(--theme-color-separator);
      border-radius: 0px !important;
    }
  }

  &__fixed-buttons {
    --ma: var(--xl);
    width: var(--page-width);
    position: fixed;
    left: 0;
    bottom: var(--ma);
    padding: 0 var(--ma);
    z-index: 10;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    & > * {
      pointer-events: all;
    }
  }

  @media (max-width: $breakpoint-md-max) {
    &__fixed-buttons {
      --ma: var(--lg);
      bottom: calc(var(--computed-mobile-panel-height) + var(--ma));
    }
  }

  @media (max-width: $breakpoint-xs-max) {
    &__fixed-buttons {
      --ma: var(--md);
    }
  }
}
</style>
