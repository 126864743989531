import type { ResetPasswordApprovePayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthResetPasswordApproveMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: ResetPasswordApprovePayload) => api.auth.resetPasswordApprove(payload)
  })
}
