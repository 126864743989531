import { useQuery } from 'src/composables'
import type { MaybeRefOrGetter } from 'vue'

export function useApiCartGetPromoItemsQuery(
  options?: Partial<{ enabled: MaybeRefOrGetter<boolean> }>
) {
  return useQuery({
    queryKey: ({ queryKeys, locale }) => queryKeys.cart.getPromoItems(locale).queryKey,
    queryFn: ({ api }) => api.cart.getPromoItems(),
    enabled: options?.enabled,
    requiresAuth: true,
    requiresUserKey: true
  })
}
