<template>
  <base-form @submit="onSubmit">
    <base-form-item :label="isUsed ? t('messages.usedBonuses') : t('actions.useBonuses')">
      <div class="row q-col-gutter-x-md">
        <div class="col">
          <app-input
            v-model="fieldValue"
            type="number"
            :placeholder="fieldPlaceholder"
            :readonly="isUsed"
            @blur="onBlur"
          >
            <template v-if="!isUsed" #append>
              <app-link
                :label="t('translate.max')"
                color="accent"
                class="text-body1"
                @click="onMax"
              />
            </template>
          </app-input>
        </div>

        <div class="col-auto">
          <app-button
            v-if="isUsed"
            color="dark"
            square
            :icon="iconTrashCan"
            :loading="resetBonusesMutation.isPending.value"
            @click="onReset"
          />

          <app-button
            v-else
            type="submit"
            color="primary"
            outline
            :label="t('actions.use')"
            :disabled="!fieldValue"
            :loading="applyBonusesMutation.isPending.value"
          />
        </div>
      </div>
    </base-form-item>
  </base-form>
</template>

<script lang="ts" setup>
import iconTrashCan from 'assets/icons/light/trash-can.svg?raw'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import { ref } from 'vue'
import { useCartApplyBonusesMutation, useCartResetBonusesMutation } from 'src/composables/cart/mutations'
import { useNotify } from 'src/composables'
import { computed } from 'vue'
import { isNil } from 'lodash'
import { useI18n } from 'src/composables/useI18n'
import { watch } from 'vue'

export type Props = {
  availableBonuses: number
  usedBonuses?: number
}

const props = defineProps<Props>()

const { t } = useI18n()

const applyBonusesMutation = useCartApplyBonusesMutation()

const resetBonusesMutation = useCartResetBonusesMutation()

const { showError } = useNotify()

const isUsed = computed(() => !!props.usedBonuses)

const fieldValue = ref<number | undefined>(undefined)

const fieldPlaceholder = computed(() => `1 - ${props.availableBonuses}`)

const onBlur = () => {
  if (isNil(fieldValue.value)) return
  fieldValue.value = Math.min(Math.max(fieldValue.value, 1), props.availableBonuses)
}

const onMax = () => {
  fieldValue.value = props.availableBonuses
}

const onSubmit = async () => {
  if (!fieldValue.value) return

  try {
    await applyBonusesMutation.mutateAsync({ bonuses: fieldValue.value })
    resetBonusesMutation.reset()
  } catch (e) {
    showError(e)
  }
}

const onReset = async () => {
  try {
    await resetBonusesMutation.mutateAsync()
    applyBonusesMutation.reset()
  } catch (e) {
    showError(e)
  }
}

watch(() => props.usedBonuses, (value) => {
  fieldValue.value = value || undefined

  if (!value) {
    applyBonusesMutation.reset()
  } else {
    resetBonusesMutation.reset()
  }
}, { immediate: true })
</script>

<style lang="scss" scoped>

</style>
