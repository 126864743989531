<template>
  <div class="search-group">
    <search-category-head
      v-if="group.category"
      :title="group.category.name"
      :to="RouterService.getRouteLocation('category', { locale, slug: group.category.slug })"
      class="search-group__head"
    />

    <div class="search-group__products">
      <product-card-small
        v-for="product in group.products.items"
        :key="product.id"
        :product="product"
      />
    </div>

    <app-link
      v-if="group.itemsLeft"
      color="accent"
      dashed
      :label="`${t('actions.showMoreCount', { n: group.itemsLeft })}...`"
      class="search-group__more"
      @click="onSelect"
    />
  </div>
</template>

<script lang="ts" setup>
import SearchCategoryHead from 'src/components/search/SearchCategoryHead.vue'
import ProductCardSmall from 'src/components/product/card/ProductCardSmall.vue'
import type { SearchResponseProductGroup, SearchResponseCategory } from 'src/models/catalog/search'
import RouterService from 'src/services/router.service'
import { useI18n } from 'src/composables/useI18n'

interface Emits {
  (e: 'select', value: SearchResponseCategory['id']): void
}

interface Props {
  group: SearchResponseProductGroup
}

const props = defineProps<Props>()

const emit = defineEmits<Emits>()

const { locale, t } = useI18n()

const onSelect = () => {
  const categoryId = props.group.category?.id
  if (categoryId) emit('select', categoryId)
}
</script>

<style lang="scss" scoped>
.search-group {
  --gap-y: var(--md);
  &__head {
    margin-bottom: var(--lg);
  }
  &__products {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--gap-y);
  }
  &__more {
    margin-top: var(--gap-y);
  }
}
</style>
