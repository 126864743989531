import { useNotify } from 'src/composables'
import { useApi } from 'src/composables'
import { useAsyncState } from '@vueuse/core'

export interface UseUserExistsState {
  username: string,
  isExists: boolean
}

export interface UseUserExistsOptions {
  onSuccess?: (state: UseUserExistsState) => void
}

export function useUserExists(options?: UseUserExistsOptions) {
  const api = useApi()
  const { showError } = useNotify()

  const asyncState = useAsyncState(
    async (username: string): Promise<UseUserExistsState> => {
      const isExists = await api.auth.checkAccountExistence({ username })
      return { username, isExists }
    }, {
      username: '',
      isExists: false
    }, {
      immediate: false,
      onError: (error) => showError(error),
      onSuccess: (state) => options?.onSuccess?.(state)
    }
  )

  const { state, isLoading } = asyncState

  const execute = (username: string) => asyncState.execute(undefined, username)

  return {
    state,
    isPending: isLoading,
    execute
  }
}
