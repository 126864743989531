<template>
  <header :class="['header', { 'header--outline': outline }]">
    <div class="header__inner">
      <app-container class="header__container">
        <div class="header__left">
          <app-logo class="header__logo" />

          <app-button
            class="header__catalog-button gt-md"
            :icon="catalog.icon"
            :label="catalog.label"
            color="primary"
            rounded
            @click="catalog.onClick"
          />
        </div>

        <search-input
          v-model="searchText"
          class="header__search-input gt-sm"
          @focus="showSearch = true"
          @clear="showSearch = false"
          @submit="showSearch = false"
        />

        <div class="header__right">
          <q-no-ssr>
            <app-button
              :icon="isDark ? iconMoon : iconSunBright"
              round
              outline
              class="header__theme-toggle"
              @click="toggleDarkTheme"
            />
          </q-no-ssr>

          <locale-toggle class="header__locale-toggle" />

          <q-no-ssr>
            <app-button
              class="gt-md"
              :icon="account.icon"
              :label="account.label"
              :loading="account.loading"
              color="dark"
              rounded
              @click="account.onClick"
            />
          </q-no-ssr>

          <q-no-ssr>
            <app-button
              class="gt-md"
              :icon="cart.icon"
              :label="cart.badge"
              :loading="cart.loading"
              color="primary"
              rounded
              @click="cart.onClick"
            />
          </q-no-ssr>

          <app-button
            class="lt-md"
            :icon="iconMagnifyingGlass"
            outline
            round
            @click="$q.dialog({ component: SearchModal })"
          />
        </div>
      </app-container>

      <q-no-ssr>
        <header-search-results
          v-model:showSearch="showSearch"
          v-model:searchText="searchText"
          class="header__search-results"
        />
      </q-no-ssr>
    </div>
  </header>
</template>

<script setup lang="ts">
import iconMagnifyingGlass from 'assets/icons/light/magnifying-glass.svg?raw'
import iconSunBright from 'assets/icons/light/sun-bright.svg?raw'
import iconMoon from 'assets/icons/light/moon.svg?raw'
import SearchInput from 'src/components/search/SearchInput.vue'
import SearchModal from 'src/components/search/SearchModal.vue'
import HeaderSearchResults from './HeaderSearchResults.vue'
import LocaleToggle from 'src/components/locale/LocaleToggle.vue'
import { useDark } from 'src/composables/dark'
import { useQuasar } from 'quasar'
import { useNav } from 'src/composables/nav'
import { ref } from 'vue'

interface Props {
  outline?: boolean
}

withDefaults(defineProps<Props>(), {
  outline: false
})

const $q = useQuasar()

const { cart, account, catalog } = useNav()

const { isDark, toggle: toggleDarkTheme } = useDark()

const showSearch = ref(false)
const searchText = ref('')
</script>

<style scoped lang="scss">
.header {
  --gx: var(--lg);
  --header-height: 68px;
  --header-radius: var(--md);
  --header-border: transparent;

  &__inner {
    position: relative;
    z-index: 2;
    background-color: var(--theme-color-bg);
    transition: background-color var(--trs-1), border-radius var(--trs-1), border-color var(--trs-1);
    border-radius: 0 0 var(--header-radius) var(--header-radius);
    border-bottom: 1px solid var(--header-border);
    height: var(--header-height);
  }

  &__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    gap: var(--gx);
    position: relative;
    z-index: 2;
  }

  &__search-input {
    flex: 1 1 auto;
    position: relative;
    z-index: 11;
  }

  &__search-results {
    position: absolute;
    z-index: 1;
    width: 100vw;
    top: 0;
    left: 0;
  }

  &__left {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    gap: var(--gx);
  }

  &__right {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    gap: $md;
  }

  &__logo {
    width: 140px;
    color: inherit;
  }

  @media (max-width: $breakpoint-md-max) {
    --header-radius: 0px;
    --header-border: var(--theme-color-separator);
    --gx: var(--md);
    --header-height: 60px;

    &__logo {
      width: 120px;
    }
  }

  @media (max-width: $breakpoint-xs-max) {
    &__logo {
      width: 110px;
    }

    &__right {
      gap: var(--sm);
    }
  }
}

.header--outline {
  --header-radius: 0px;
  --header-border: var(--theme-color-separator);
}
</style>
