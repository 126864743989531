<template>
  <div class="cart-item">
    <app-img
      class="cart-item__img"
      :src="image"
      :to="to"
      :title="name"
      :alt="name"
      :ratio="1"
    />
    <div class="cart-item__content">
      <div class="cart-item__head">
        <app-link :to="to" :title="name" class="cart-item__name">{{ name }}</app-link>
        <app-button
          round
          outline
          :icon="iconTrashCan"
          color="muted"
          class="cart-item__delete"
          :loading="deleteMethod.isPending.value"
          @click="deleteMethod.handler()"
        />
      </div>

      <div class="cart-item__bottom">
        <base-price
          class="cart-item__price"
          :price="item.price"
          :old-price="item.oldPrice"
          :discount-badge="true"
        />

        <base-input-counter
          :model-value="count"
          :min="1"
          :debounce="INPUT_DEBOUNCE"
          class="cart-item__counter"
          @update:model-value="count => changeMethod.handler(count)"
        />
      </div>

      <div v-if="showMessages && discountMessage" class="cart-item__discount-message text-caption">
        {{ discountMessage }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import iconTrashCan from 'assets/icons/light/trash-can.svg?raw'
import BasePrice from 'src/components/base/BasePrice.vue'
import BaseInputCounter from 'src/components/base/BaseInputCounter.vue'
import { useCartItem } from 'src/composables/cart/item'
import type { CartItem } from 'src/models/cart/item'
import { INPUT_DEBOUNCE } from 'src/config'

interface Props {
  item: CartItem
  showMessages?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showMessages: false
})

const {
  name,
  image,
  to,
  count,
  discountMessage,
  changeMethod,
  deleteMethod
} = useCartItem(() => props.item)
</script>

<style lang="scss" scoped>
.cart-item {
  display: flex;
  align-items: flex-start;
  &__img {
    width: calc(80px + var(--md));
    flex: 0 0 auto;
    border-radius: var(--global-border-radius-inner);
  }
  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: var(--md);
  }
  &__name {
    @include line-clamp(2);
    line-height: 20px;
    flex: 1 1 auto;
  }
  &__head {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--md);
  }
  &__delete {
    flex: 0 0 auto;
    margin-left: var(--md);
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__discount-message {
    margin-top: var(--sm);
    color: var(--theme-color-discount);
  }
}
</style>
