<template>
  <app-content
    class="cart-promo-items"
    bg="primary-muted"
  >
    <div class="cart-promo-items__container">
      <div
        v-if="description"
        class="cart-promo-items__head"
      >
        <div class="cart-promo-items__text">
          <ui-icon
            :icon="iconGift"
            class="cart-promo-items__text-icon"
          />
          <span v-html="description" />
        </div>

        <slider-counter
          v-if="slider"
          class="cart-promo-items__slider-counter"
          :slider="slider"
        />
      </div>

      <div class="cart-promo-items__products">
        <slider-root
          :breakpoints="{
            [BREAKPOINT.xs]: {
              slidesPerView: 1
            },
            [BREAKPOINT.sm]: {
              slidesPerView: 'auto'
            }
          }"
          :loop="true"
          class="cart-promo-items__slider"
          @state="onStateUpdate"
        >
          <div
            v-for="item in productItems"
            :key="item.id"
            class="cart-promo-items__product"
          >
            <product-card-small
              :product="item"
              class="cart-promo-items__product"
              background
            />
          </div>
        </slider-root>
      </div>

      <div class="cart-promo-items__bottom">
        <div>
          <app-link
            v-if="urlNormalized"
            :to="urlNormalized"
            :label="t('actions.showMore')"
            :icon-right="iconCircleCaretRight"
            color="accent"
            class="cart-promo-items__show-more-link"
          />
        </div>

        <slider-controls
          v-if="slider"
          :slider="slider"
          class="cart-promo-items__slider-controls"
        />
      </div>
    </div>
  </app-content>
</template>

<script lang="ts" setup>
import iconCircleCaretRight from 'assets/icons/solid/circle-caret-right.svg?raw'
import iconGift from 'assets/icons/light/gift.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import ProductCardSmall from 'src/components/product/card/ProductCardSmall.vue'
import SliderRoot, { type SliderRootState } from 'src/components/slider/SliderRoot.vue'
import SliderControls from 'src/components/slider/SliderControls.vue'
import SliderCounter from 'src/components/slider/SliderCounter.vue'
import type { ProductData } from 'src/models/product'
import { Product } from 'src/models/product'
import { computed } from 'vue'
import { ref } from 'vue'
import { BREAKPOINT } from 'src/config'
import { useI18n } from 'src/composables/useI18n'
import { useNormalizeUrl } from 'src/composables/utils'

const props = defineProps<{
  products: ProductData[]
  description?: string
  url?: string
}>()

const normalizeUrl = useNormalizeUrl()

const { t } = useI18n()

const slider = ref<SliderRootState | null>(null)

const productItems = computed(() => props.products.map((p) => new Product(p)))

const urlNormalized = computed(() => {
  if (!props.url) return
  return normalizeUrl(props.url)
})

function onStateUpdate(state: SliderRootState) {
  slider.value = state
}
</script>

<style lang="scss" scoped>
.cart-promo-items {
  border-radius: var(--global-border-radius);

  &__container {
    padding: var(--md);
  }
  &__head {
    margin-bottom: var(--md);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
  }
  &__slider-counter {
    flex: 0 0 auto;
    background-color: var(--theme-color-bg);
  }
  &__text {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    flex: 1 1 auto;
    max-width: 17.5rem;

    &-icon {
      font-size: 2.125rem;
      flex: 0 0 auto;
      color: var(--theme-color-primary);
    }

    &:deep(strong) {
      color: var(--theme-color-primary);
    }
  }
  &__slider {
    --space-between: var(--md) !important;
    --wrapper-px: var(--md) !important;
  }
  &__slider-controls {
    justify-content: end;
    justify-self: end;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--md);
  }

  @media (min-width: $breakpoint-sm-min) {
    &__product {
      width: 18rem;
    }
  }
}
</style>
