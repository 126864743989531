<template>
  <div class="cart-item-gift">
    <app-content
      class="cart-item-gift__inner"
      bg="primary-muted"
      rounded
    >
      <div class="cart-item-gift__icon">
        <ui-icon :icon="iconGift" />
      </div>
      <div class="cart-item-gift__text" v-html="text" />
    </app-content>
  </div>
</template>

<script lang="ts" setup>
import iconGift from 'assets/icons/light/gift.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'

const props = defineProps<{
  text?: string
}>()

const { t } = useI18n()

const text = computed(() => {
  return props.text ?? t('CartItemGift.text')
})
</script>

<style lang="scss" scoped>
.cart-item-gift {
  &__inner {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: var(--md);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color-primary);
    flex: 0 0 auto;
    font-size: 2.125rem;
  }

  &__text {
    flex: 1 1 auto;
    &:deep(strong) {
      color: var(--theme-color-primary);
    }
  }
}
</style>
