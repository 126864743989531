<template>
  <transition name="fade">
    <div v-if="isVisible" class="header-search">
      <app-content background class="header-search__wrapper">
        <app-separator />
        <div class="header-search__scroll-wrapper">
          <div class="header-search__inner">
            <div v-if="showTabs" class="header-search__categories-tabs">
              <app-container>
                <base-tabs
                  v-model="selectedTab"
                  :tabs="categoriesTabs"
                />
              </app-container>
            </div>

            <app-container class="header-search__container">
              <app-content
                v-if="hasResults"
                :loading="searchIsPending"
                class="header-search__content"
              >
                <app-content
                  v-if="selectedCategoryId && selectedCategory"
                  class="header-search-category"
                >
                  <div :key="searchCategoryData?.category?.id" class="header-search-category__content">
                    <div class="header-search-category__head">
                      <search-category-head
                        :title="selectedCategory.name"
                        :to="getCategoryTo(selectedCategory.slug)"
                      />
                    </div>

                    <app-content
                      v-if="searchCategoryData && showCategoryResults"
                      :loading="isCategoryResultsLoading"
                      class="header-search-category__results-wrapper"
                    >
                      <div :key="searchCategoryData.page" class="header-search-category__results">
                        <template v-if="showCategoryResults">
                          <product-card-small
                            v-for="product in searchCategoryData?.products.items"
                            :key="product.id"
                            :product="product"
                          />
                        </template>

                        <template v-else>
                          <product-card-small-skeleton
                            v-for="item in Math.min(selectedCategory.itemsCount, 12)"
                            :key="item"
                          />
                        </template>
                      </div>
                    </app-content>

                    <div v-else class="header-search-category__results">
                      <product-card-small-skeleton
                        v-for="item in Math.min(selectedCategory.itemsCount, 12)"
                        :key="item"
                      />
                    </div>

                    <div v-if="showCategoryControls" class="header-search-category__controls">
                      <app-button
                        :icon="iconCheveronLeft"
                        :color="searchCategoryData?.prevPage ? 'muted' : 'light'"
                        :disabled="!searchCategoryData?.prevPage"
                        square
                        @click="changePage(searchCategoryData?.prevPage)"
                      />

                      <app-button
                        :icon="iconChevronRight"
                        :color="searchCategoryData?.nextPage ? 'muted' : 'light'"
                        :disabled="!searchCategoryData?.nextPage"
                        square
                        @click="changePage(searchCategoryData?.nextPage)"
                      />
                    </div>
                  </div>
                </app-content>

                <app-content
                  v-else
                  class="header-search__groups"
                >
                  <div
                    v-for="group in searchData?.productGroups.items"
                    :key="group.id"
                    class="header-search-group"
                  >
                    <search-category-head
                      v-if="group.category"
                      :title="group.category.name"
                      :to="getCategoryTo(group.category.slug)"
                      class="header-search-group__head"
                    />
                    <div class="header-search-group__products">
                      <product-card-small
                        v-for="product in group.products.items"
                        :key="product.id"
                        :product="product"
                      />
                    </div>
                    <app-link
                      v-if="group.category && group.itemsLeft"
                      color="accent"
                      dashed
                      :label="`${t('actions.showMoreCount', { n: group.itemsLeft })}...`"
                      class="header-search-group__more"
                      @click="selectedCategoryId = group.category?.id ?? null"
                    />
                  </div>
                </app-content>
              </app-content>

              <div v-else class="header-search__no-results">
                <search-not-found />
              </div>
            <!--  -->
            </app-container>
          </div>
        </div>
      </app-content>

      <div class="header-search__overlay" @click="hide" />
    </div>
  </transition>
</template>

<script lang="ts" setup>
import iconChevronRight from 'assets/icons/light/chevron-right.svg?raw'
import iconCheveronLeft from 'assets/icons/light/chevron-left.svg?raw'
import BaseTabs from 'src/components/base/BaseTabs.vue'
import SearchNotFound from 'src/components/search/SearchNotFound.vue'
import ProductCardSmall from 'src/components/product/card/ProductCardSmall.vue'
import ProductCardSmallSkeleton from 'src/components/product/card/ProductCardSmallSkeleton.vue'
import SearchCategoryHead from 'src/components/search/SearchCategoryHead.vue'
import { useSearch } from 'src/composables/catalog/search'
import { computed, toRef, watch } from 'vue'
import RouterService from 'src/services/router.service'
import { useScrollLock } from 'src/composables'
import { useRoute } from 'vue-router'
import { useI18n } from 'src/composables/useI18n'
import type { CategorySlug, GetCategoryKnownParams } from 'src/models/catalog/category'

interface Emits {
  (e: 'update:showSearch', value: Props['showSearch']): void
  (e: 'update:searchText', value: Props['searchText']): void
}

interface Props {
  showSearch: boolean
  searchText: string
}

const props = defineProps<Props>()

const emit = defineEmits<Emits>()

const isLocked = useScrollLock()

const route = useRoute()

const { t, locale } = useI18n()

const {
  showResults,
  searchText,
  searchData,
  searchIsPending,
  searchCategoryData,
  selectedCategoryId,
  selectedCategory,
  isCategoryResultsLoading,
  showCategoryResults,
  showCategoryControls,
  showTabs,
  categoriesTabs,
  selectedTab,
  hasResults,
  changePage
} = useSearch({
  enabled: toRef(props, 'showSearch')
})

const isVisible = computed(() => {
  return props.searchText && props.showSearch && showResults.value
})

const getCategoryTo = (slug: CategorySlug) => {
  const query: GetCategoryKnownParams = {
    searchText: searchText.value
  }

  return RouterService.getRouteLocation('category', { locale: locale.value, slug }, query)
}

watch(() => props.searchText, (value) => {
  searchText.value = value
}, { immediate: true })

watch(isVisible, (value) => {
  if (value) isLocked.value = true
  else isLocked.value = false
})

watch(() => route.fullPath, () => {
  hide()
})

const hide = () => {
  emit('update:showSearch', false)
}
</script>

<style lang="scss" scoped>
.header-search {
  --gap-x: var(--lg);
  --gap-y: var(--md);
  --columns: 3;
  background-color: var(--theme-color-dialog-overlay);
  height: var(--screen-height);
  &__wrapper {
    position: relative;
    padding-top: calc(var(--header-height) - 1px);
    z-index: 2;
    max-height: 100%;
  }
  &__scroll-wrapper {
    overflow-y: auto;
    max-height: calc(var(--screen-height) - var(--header-height));
  }
  &__overlay {
    background-color: var(--theme-color-dialog-overlay);
    transition: background-color var(--trs-1);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &__categories-tabs {
    margin-bottom: var(--lg);
    overflow: hidden;
  }
  &__inner {
    padding: var(--lg) 0 var(--xl) 0;
  }
  &__results-wrapper {
    position: relative;
  }
  &__results {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: var(--gap-y) var(--gap-x);
  }
  &__category-item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-color-separator);
    }
  }
  &__category-button {
    width: 100%;
  }
  &__groups {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: var(--lg) var(--gap-x);
  }

  @media (max-width: $breakpoint-md-max) {
    --columns: 2;
  }
  @media (max-width: $breakpoint-sm-max) {
    --columns: 1;
    &__inner {
      padding: 0 0 var(--lg) 0;
    }
  }
}

.header-search-category {
  position: relative;
  &__head {
    margin-bottom: var(--lg);
  }
  &__controls {
    display: flex;
    gap: var(--sm);
    margin-top: var(--lg);
  }
  &__results {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: var(--gap-y) var(--gap-x);
  }
}

.header-search-group {
  &__head {
    margin-bottom: var(--lg);
  }
  &__products {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--gap-y);
  }
  &__more {
    margin-top: var(--gap-y);
  }
}
</style>
