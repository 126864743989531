<template>
  <div class="cart-checkout">
    <div class="cart-checkout__wrapper" data-scroll-lock-scrollable>
      <div class="cart-checkout__head">
        <div class="cart-checkout__head-title text-h4">{{ t('translate.checkout') }}</div>
        <app-button
          class="cart-checkout__head-button"
          round
          outline
          :icon="iconChevronRight"
          @click="emit('close')"
        />
      </div>

      <checkout
        :cart="cart"
        :loading="loading"
        class="cart-checkout__content"
        @success="emit('success')"
        @select-delivery="value => emit('selectDelivery', value)"
        @select-payment-method="value => emit('selectPaymentMethod', value)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import iconChevronRight from 'assets/icons/light/chevron-right.svg?raw'
import Checkout from 'src/components/cart/Checkout.vue'
import type { DeliveryTechnology } from 'src/models/delivery'
import type { Cart } from 'src/models/cart'
import type { PaymentMethod } from 'src/models/payment'
import { useI18n } from 'src/composables/useI18n'

defineProps<{
  cart: Cart
  loading?: boolean
}>()

const emit = defineEmits<{
  selectDelivery: [DeliveryTechnology | undefined]
  selectPaymentMethod: [PaymentMethod | undefined]
  success: []
  close: []
}>()

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.cart-checkout {
  position: absolute;
  right: 100%;
  top: 0;
  height: 100%;
  width: var(--modal-aside-content-width);
  &__wrapper {
    position: relative;
    background-color: var(--theme-color-bg);
    transition: background-color var(--trs-1);
    border-right: 1px solid var(--theme-color-separator);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__head {
    flex: 0 0 auto;
    width: 100%;
    height: var(--modal-aside-head-height);
    position: sticky;
    z-index: 2;
    top: 0;
    padding: 0 var(--modal-aside-px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--grid-gutter);
    background-color: var(--theme-color-bg);
    border-bottom: 1px solid var(--theme-color-separator);
  }
  &__content {
    --checkout-px: var(--modal-aside-px) !important;
    --checkout-py: var(--modal-aside-py) !important;
  }
  &__head-title {
    margin: 0;
  }
}
</style>
