<template>
  <base-no-data
    :icon="iconCartShopping"
    :title="t('messages.emptyCart')"
    :message="t('messages.addToCart')"
    :action="t('actions.continueShopping')"
    @action="emit('action')"
  />
</template>

<script lang="ts" setup>
import iconCartShopping from 'assets/icons/light/cart-shopping.svg?raw'
import BaseNoData from 'src/components/base/BaseNoData.vue'
import { useI18n } from 'src/composables/useI18n'

const emit = defineEmits<{
  action: []
}>()

const { t } = useI18n()
</script>

<style lang="scss" scoped>

</style>
