<template>
  <div class="auth-widget-registration">
    <base-form
      :title="title ?? t('messages.niceToMeetYou')"
      :message="message ?? t('messages.letsCreateAccount')"
      :loading="isPending"
      @submit="onSubmit"
    >
      <transition-group name="slide">
        <div v-if="!codeSent">
          <div class="row">
            <div class="column q-col-gutter-y-md full-width">
              <base-form-item
                :errors="v$.registration.username.$errors"
                :label="t('inputs.phone.label')"
              >
                <base-input-phone
                  v-model="state.registration.username"
                  :error="v$.registration.username.$error"
                  username
                  @blur="v$.registration.username.$touch"
                  @complete="userExists.execute"
                />
              </base-form-item>

              <base-form-item-preset
                v-model="state.registration.firstName"
                preset="firstName"
                :error="v$.registration.firstName.$error"
                :errors="v$.registration.firstName.$errors"
                @blur="v$.registration.firstName.$touch"
              />

              <base-form-item-preset
                v-model="state.registration.lastName"
                preset="lastName"
                :error="v$.registration.lastName.$error"
                :errors="v$.registration.lastName.$errors"
                @blur="v$.registration.lastName.$touch"
              />

              <base-form-item-preset
                v-model="state.registration.email"
                preset="email"
                :error="v$.registration.email.$error"
                :errors="v$.registration.email.$errors"
                @blur="v$.registration.email.$touch"
              />

              <base-form-item
                :label="t('inputs.password.label')"
                :errors="v$.registration.password.$errors"
              >
                <base-input-password
                  v-model="state.registration.password"
                  is-new
                  :error="v$.registration.password.$error"
                  @blur="v$.registration.password.$touch"
                />
              </base-form-item>
            </div>
          </div>

          <base-form-item class="q-mt-lg">
            <app-button
              type="submit"
              color="primary"
              :loading="isPending"
              full-width
              :label="t('actions.continue')"
            />

            <template #after>
              <base-form-helper
                :label="t('questions.alreadyRegistered')"
                :action="t('actions.login')"
                @action="emit('login')"
              />
            </template>
          </base-form-item>

          <base-text-separator :text="t('translate.or')" class="q-my-md" />

          <auth-widget-login-by-social
            @success="emit('success')"
            @need-approve="(provider, data) => emit('loginBySocialApprove', provider, data)"
          />
        </div>

        <div v-else>
          <base-form-item-code
            v-model="state.approve.phoneCode"
            :code-sent="codeSent"
            :error="v$.approve.phoneCode.$error"
            :errors="v$.approve.phoneCode.$errors"
            autofocus
            @blur="v$.approve.phoneCode.$touch"
            @complete="onSubmit"
          />

          <app-button
            class="q-mt-lg"
            type="submit"
            color="primary"
            :loading="isPending"
            full-width
            :label="t('actions.registration')"
          />
        </div>
      </transition-group>
    </base-form>
  </div>
</template>

<script setup lang="ts">
import type { RegistrationPayload, RegistrationApprovePayload } from 'src/api/modules/auth.module'
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItemPreset from 'src/components/base/BaseFormItemPreset.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import BaseFormHelper from 'src/components/base/BaseFormHelper.vue'
import BaseFormItemCode from 'src/components/base/BaseFormItemCode.vue'
import BaseInputPhone from 'src/components/base/BaseInputPhone.vue'
import BaseInputPassword from 'src/components/base/BaseInputPassword.vue'
import BaseTextSeparator from 'src/components/base/BaseTextSeparator.vue'
import AuthWidgetLoginBySocial from './AuthWidgetLoginBySocial.vue'
import {
  useUserExists, useLogin, useI18n, useNotify, useVuelidate, useVuelidateValidators,
  useApiAuthRegistrationMutation, useApiAuthRegistrationApproveMutation
} from 'src/composables'
import { watch, computed, reactive, ref } from 'vue'

const props = defineProps<{
  username?: string
  title?: string
  message?: string
}>()

const emit = defineEmits<{
  'success': []
  'login': [username?: string]
  'loginBySocialApprove': [provider: SocialProvider, data: LoginSocialProviderResponse]
}>()

const { t } = useI18n()

const { showError } = useNotify()

const validators = useVuelidateValidators()

const login = useLogin()

const registrationMutation = useApiAuthRegistrationMutation()

const registrationApproveMutation = useApiAuthRegistrationApproveMutation()

const state = reactive<{
  registration: {
    [K in keyof Required<RegistrationPayload>]: RegistrationPayload[K] | undefined
  },
  approve: {
    [K in keyof Required<RegistrationApprovePayload>]: RegistrationApprovePayload[K] | undefined
  }
}>({
  registration: {
    firstName: undefined,
    lastName: undefined,
    username: undefined,
    password: undefined,
    email: undefined
  },
  approve: {
    phoneCode: undefined
  }
})

const rules = computed(() => {
  const { required, phone, password, email, phoneCode, firstName, lastName } = validators

  return {
    registration: {
      firstName: { required, firstName },
      lastName: { required, lastName },
      username: { required, phone },
      password: { required, password },
      email: { required, email },
    },
    approve: {
      phoneCode: { required, phoneCode },
    }
  }
})

const v$ = useVuelidate(rules, state, { $scope: false })

const codeSent = computed(() => registrationMutation.data.value)

const isPending = ref(false)

const userExists = useUserExists({
  onSuccess: ({ username, isExists }) => {
    if (isExists) emit('login', username)
  }
})

watch(() => props.username, (username) => {
  state.registration.username = username
}, { immediate: true })

async function registrationHandler() {
  const isValid = await v$.value.registration.$validate()
  if (isPending.value || !isValid) return

  isPending.value = true

  try {
    await registrationMutation.mutateAsync(state.registration as RegistrationPayload)
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function registrationApproveHandler() {
  const isValid = await v$.value.approve.$validate()
  if (isPending.value || !isValid) return

  isPending.value = true

  try {
    const tokenSet = await registrationApproveMutation.mutateAsync(state.approve as RegistrationApprovePayload)
    await login(tokenSet)
    emit('success')
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function onSubmit() {
  if (!codeSent.value) {
    await registrationHandler()
  } else {
    await registrationApproveHandler()
  }
}
</script>
