<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
  disabled?: boolean
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const classes = computed(() => {
  return [
    'content-block',
    {
      'content-block--muted': props.disabled || props.loading,
      'content-block--disabled': props.disabled,
      'content-block--loading': props.loading
    }
  ]
})
</script>

<style lang="scss" scoped>
.content-block {
  transition: opacity var(--trs-1);
  &--muted {
    opacity: 0.5;
    &:deep(> *) {
      pointer-events: none;
    }
  }
  &--disabled {
    cursor: not-allowed;
  }
  &--loading {
    cursor: progress;
  }
}
</style>
