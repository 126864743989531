<template>
  <transition name="fade">
    <div v-if="show" class="cookies-notification">
      <app-content preset="md" rounded class="cookies-notification__wrapper">
        <div class="q-mb-sm">
          {{ t('messages.cookiesNotification') }}
        </div>
        <div class="row q-gutter-sm">
          <div class="col-auto">
            <app-button
              :label="t('actions.accept')"
              color="primary"
              caps
              size="sm"
              @click="onAccept"
            />
          </div>

          <div class="col-auto">
            <app-button
              :label="t('actions.showDetail')"
              color="dark"
              size="sm"
              :to="normalizeUrl(PAGE_TERMS_URL)"
              @click="onAccept"
            />
          </div>
        </div>
      </app-content>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { useLocalStorage, useMounted } from '@vueuse/core'
import { PAGE_TERMS_URL } from 'src/config'
import { computed } from 'vue'
import { useI18n } from 'src/composables/useI18n'
import { useNormalizeUrl } from 'src/composables/utils'

const isMounted = useMounted()

const cookieAccept = useLocalStorage('cookie_accept', false)

const show = computed(() => isMounted.value && !cookieAccept.value)

const { t } = useI18n()

const normalizeUrl = useNormalizeUrl()

const onAccept = () => {
  cookieAccept.value = true
}
</script>

<style lang="scss" scoped>
.cookies-notification {
  pointer-events: none;
  position: fixed;
  width: var(--page-width);
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 0 var(--container-px) var(--md) var(--container-px);
  display: flex;
  justify-content: center;
  &__wrapper {
    max-width: 600px;
    box-shadow: var(--shadow);
    pointer-events: all;
  }
}
</style>
