<template>
  <div class="auth-widget-login">
    <transition-group name="slide">
      <div v-if="step === Step.UserExists">
        <base-form
          :title="title || t('translate.hello')"
          :message="message || t('actions.logInByPhone')"
          :loading="isPending"
          @submit="onSubmit"
        >
          <base-form-item
            :errors="v$.username.$errors"
            :label="t('inputs.phone.label')"
          >
            <base-input-phone
              v-model="state.username"
              :error="v$.username.$error"
              login
              @blur="v$.username.$touch"
              @complete="onSubmit"
            />
          </base-form-item>

          <base-form-item class="q-mt-lg">
            <app-button
              type="submit"
              color="primary"
              full-width
              :loading="userExists.isPending.value"
              :label="t('actions.continue')"
            />

            <template #after>
              <base-form-helper
                :label="t('questions.notRegistered')"
                :action="t('actions.registration')"
                @action="emit('registration')"
              />
            </template>
          </base-form-item>
        </base-form>

        <base-text-separator :text="t('translate.or')" class="q-my-md" />

        <auth-widget-login-by-social
          @success="emit('success')"
          @need-approve="(provider, data) => emit('loginBySocialApprove', provider, data)"
        />
      </div>

      <base-form
        v-else
        :title="t('translate.welcomeBack')"
        :loading="isPending"
        @submit="onSubmit"
      >
        <div class="row">
          <div class="column q-col-gutter-y-md full-width">
            <transition-group name="slide">
              <base-form-item
                v-if="step === Step.Login && state.username"
                :label="t('messages.enterPasswordToLoginTo', { username: formatFullPhoneNumber(state.username) })"
                :errors="v$.password.$errors"
              >
                <base-input-password
                  v-model="state.password"
                  autofocus
                  :error="v$.password.$error"
                  @blur="v$.password.$touch"
                />

                <template #after>
                  <base-form-helper
                    :action="t('questions.forgotPassword')"
                    @action="onToggleResetPassword"
                  />
                </template>
              </base-form-item>

              <base-form-item-code
                v-else-if="step === Step.LoginApprove && codeSent"
                v-model="state.phoneCode"
                :code-sent="codeSent"
                :error="v$.phoneCode.$error"
                :errors="v$.phoneCode.$errors"
                autofocus
                @blur="v$.phoneCode.$touch"
                @complete="onSubmit"
              />
            </transition-group>
          </div>
        </div>

        <base-form-item class="q-mt-lg">
          <app-button
            type="submit"
            color="primary"
            :loading="isPending"
            full-width
            :label="t('actions.continue')"
          />

          <template #after>
            <base-form-helper
              :action="t('actions.loginToAnotherAccount')"
              :label="t('translate.or')"
              position="center"
              @action="onChangeAccount"
            />
          </template>
        </base-form-item>
      </base-form>
    </transition-group>
  </div>
</template>

<script lang="ts" setup>
import type { LoginPayload, LoginApprovePayload } from 'src/api/modules/auth.module'
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import BaseFormHelper from 'src/components/base/BaseFormHelper.vue'
import BaseInputPhone from 'src/components/base/BaseInputPhone.vue'
import BaseFormItemCode from 'src/components/base/BaseFormItemCode.vue'
import BaseInputPassword from 'src/components/base/BaseInputPassword.vue'
import BaseTextSeparator from 'src/components/base/BaseTextSeparator.vue'
import AuthWidgetLoginBySocial from './AuthWidgetLoginBySocial.vue'
import {
  useUserExists, useLogin, useI18n, useNotify,
  useApiAuthLoginMutation, useApiAuthLoginApproveMutation,
  useVuelidate, useVuelidateValidators
} from 'src/composables'
import { computed, ref, reactive, watch } from 'vue'
import { formatFullPhoneNumber } from 'src/utils/strings'

enum Step {
  UserExists,
  Login,
  LoginApprove
}

const props = defineProps<{
  title?: string
  message?: string
  username?: string
}>()

const emit = defineEmits<{
  'success': []
  'loginBySocialApprove': [provider: SocialProvider, data: LoginSocialProviderResponse]
  'registration': [username?: string]
  'resetPassword': [username?: string]
}>()

const { t } = useI18n()

const { showError } = useNotify()

const validators = useVuelidateValidators()

const login = useLogin()

const loginMutation = useApiAuthLoginMutation()

const loginApproveMutation = useApiAuthLoginApproveMutation()

const state = reactive<{
  [K in keyof (LoginPayload & LoginApprovePayload)]: (LoginPayload & LoginApprovePayload)[K] | undefined
}>({
  username: undefined,
  password: undefined,
  phoneCode: undefined
})

const rules = computed(() => {
  const { required, phone, phoneCode } = validators

  return {
    username: { required, phone },
    password: { required },
    phoneCode: { required, phoneCode },
  }
})

const v$ = useVuelidate(rules, state, { $scope: false })

const step = ref<Step>(Step.UserExists)

const codeSent = computed(() => loginMutation.data.value?.codeSent)

const isPending = ref(false)

const userExists = useUserExists()

watch(() => props.username, (username) => {
  if (username) {
    step.value = Step.Login
    state.username = username
  }
}, { immediate: true })

function reset() {
  state.username = undefined
  state.password = undefined
  state.phoneCode = undefined
  v$.value.$reset()
  loginMutation.reset()
  loginApproveMutation.reset()
  step.value = Step.UserExists
}

async function loginHandler() {
  const isUsernameValid = await v$.value.username.$validate()
  const isPasswordValid = await v$.value.password.$validate()
  if (isPending.value || !isUsernameValid || !isPasswordValid) return

  isPending.value = true

  const payload = {
    username: state.username,
    password: state.password
  } as LoginPayload

  try {
    const { tokenSet, codeSent } = await loginMutation.mutateAsync(payload)
    if (tokenSet) {
      await login(tokenSet)
      emit('success')
    } else if (codeSent) {
      step.value = Step.LoginApprove
    }
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function loginApproveHandler() {
  const isPhoneCodeValid = await v$.value.phoneCode.$validate()
  if (isPending.value || !isPhoneCodeValid) return

  isPending.value = true

  const payload = {
    phoneCode: state.phoneCode
  } as LoginApprovePayload

  try {
    const tokenSet = await loginApproveMutation.mutateAsync(payload)
    await login(tokenSet)
    emit('success')
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function userExistsHandler() {
  const isUsernameValid = await v$.value.username.$validate()
  if (isPending.value || !isUsernameValid) return

  isPending.value = true

  try {
    const { username, isExists } = await userExists.execute(state.username as string)

    if (isExists) {
      step.value = Step.Login
    } else {
      emit('registration', username)
    }
  } finally {
    isPending.value = false
  }
}

async function onSubmit() {
  const mapActions: Record<Step, () => Promise<void>> = {
    [Step.UserExists]: userExistsHandler,
    [Step.Login]: loginHandler,
    [Step.LoginApprove]: loginApproveHandler
  }

  await mapActions[step.value]()
}

function onToggleResetPassword() {
  emit('resetPassword', state.username)
}

function onChangeAccount() {
  reset()
}
</script>

<style lang="scss" scoped>

</style>
