<template>
  <base-form @submit="onSubmit">
    <base-form-item :label="isUsed ? t('messages.promocodeIsUsed') : t('actions.usePromocode')">
      <div class="row q-col-gutter-x-md">
        <div class="col">
          <app-input
            v-model="promocode"
            :placeholder="t('translate.hasPromocode')"
            :readonly="isUsed"
          >
            <template #prepend>
              <ui-icon :icon="iconTicket" />
            </template>
          </app-input>
        </div>

        <div class="col-auto">
          <app-button
            v-if="isUsed"
            class="promocode__button"
            color="dark"
            square
            :icon="iconTrashCan"
            :loading="resetPromocodeMutation.isPending.value"
            @click="onReset"
          />

          <app-button
            v-else
            type="submit"
            class="promocode__button"
            color="primary"
            outline
            :label="t('actions.use')"
            :loading="applyPromocodeMutation.isPending.value"
            :disabled="$v.$invalid"
          />
        </div>
      </div>
    </base-form-item>
  </base-form>
</template>

<script lang="ts" setup>
import iconTrashCan from 'assets/icons/light/trash-can.svg?raw'
import iconTicket from 'assets/icons/light/ticket.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import { useI18n } from 'src/composables/useI18n'
import { useCartApplyPromocodeMutation, useCartResetPromocodeMutation } from 'src/composables/cart/mutations'
import { useVuelidate, useVuelidateValidators } from 'src/composables/vuelidate'
import { useNotify } from 'src/composables'
import { ref, watch, computed } from 'vue'

const props = defineProps<{
  usedPromocode?: string
}>()

const { t } = useI18n()

const applyPromocodeMutation = useCartApplyPromocodeMutation()

const resetPromocodeMutation = useCartResetPromocodeMutation()

const { required, validators } = useVuelidateValidators()

const { showError } = useNotify()

const promocode = ref<string | undefined>(undefined)

const rules = computed(() => ({
  promocode: {
    required,
    minLength: validators.minLength(2)
  }
}))

const $v = useVuelidate(rules, { promocode }, { $scope: false })

const isUsed = computed(() => !!props.usedPromocode)

const onSubmit = async () => {
  if (!promocode.value) return

  try {
    await applyPromocodeMutation.mutateAsync(promocode.value)
  } catch (error) {
    showError(error)
  }
}

const onReset = async () => {
  if (!props.usedPromocode) return

  try {
    await resetPromocodeMutation.mutateAsync()
  } catch (error) {
    showError(error)
  }
}

watch(() => props.usedPromocode, (value) => {
  promocode.value = value
  $v.value.$reset()

  if (!value) {
    applyPromocodeMutation.reset()
  } else {
    resetPromocodeMutation.reset()
  }
}, { immediate: true })
</script>

<style lang="scss" scoped>
</style>
