import type { PartialDeep } from 'type-fest'
import { Model } from 'src/models/model'
import { ModelList } from 'src/models/modelList'
import type { ProductData } from 'src/models/product'
import { ProductList } from 'src/models/catalog/productList'
import type { CategorySlug } from './category'

export type SearchResponseData = {
  results: {
    hits: SearchResponseGroupData[]
    parentCategories: SearchResponseCategoryData[]
  }
}

export type SearchCategoryResponseData = {
  results: {
    hits: [
      {
        category: SearchResponseCategoryData
        products: ProductData[]
      }
    ]
    prevPage: number
    nextPage: number
  }
}

export interface SearchResponseGroupData {
  category: SearchResponseCategoryData
  from: number
  products: ProductData[]
}

export interface SearchResponseCategoryData {
  id: number
  docCount: number
  slug: string
  name: string
  nameUk: string
  nameRu: string
  nameEn: string
}

export class SearchResponseCategory extends Model<PartialDeep<SearchResponseCategoryData>> {
  constructor(data: PartialDeep<SearchResponseCategoryData>) {
    super(data)
  }

  get id() {
    return String(this.data.id ?? this.uid)
  }

  get name() {
    return this.data.name
  }

  get slug() {
    return (this.data.slug ?? '') as CategorySlug
  }

  get itemsCount() {
    return this.data.docCount ?? 0
  }
}

export class SearchResponseCategoryList extends ModelList<typeof SearchResponseCategory> {
  constructor(items?: PartialDeep<SearchResponseCategoryData>[]) {
    super(SearchResponseCategory, items)
  }
}

export class SearchResponseProductGroup extends Model<PartialDeep<SearchResponseGroupData>> {
  constructor(data: PartialDeep<SearchResponseGroupData>) {
    super(data)
  }

  get id() {
    return this.category?.id ?? this.uid
  }

  get category() {
    return this.data.category ? new SearchResponseCategory(this.data.category) : null
  }

  get products() {
    return new ProductList(this.data.products)
  }

  get itemsLeft() {
    const value = (this.category?.itemsCount ?? 0) - this.products.items.length
    return this.data.from ?? value
  }
}

export class SearchResponseProductGroupList extends ModelList<typeof SearchResponseProductGroup> {
  constructor(items?: PartialDeep<SearchResponseGroupData>[]) {
    super(SearchResponseProductGroup, items)
  }
}

export class SearchResponse extends Model<PartialDeep<SearchResponseData>> {
  constructor(data: PartialDeep<SearchResponseData>) {
    super(data)
  }

  get parentCategories() {
    return new SearchResponseCategoryList(this.data.results?.parentCategories)
  }

  get productGroups() {
    return new SearchResponseProductGroupList(this.data.results?.hits)
  }

  get totalResults() {
    return this.parentCategories.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.itemsCount, 0
    )
  }

  get hasResults() {
    return !!this.totalResults
  }

  get singleCategoryResult() {
    return this.parentCategories.items.length === 1 ? this.parentCategories.items[0] : null
  }
}

export class SearchCategoryResponse extends Model<PartialDeep<SearchCategoryResponseData>> {
  constructor(data: PartialDeep<SearchCategoryResponseData>) {
    super(data)
  }

  private get results() {
    return this.data.results?.hits?.find((item, index) => index === 0)
  }

  get category() {
    return this.results?.category ? new SearchResponseCategory(this.results?.category) : null
  }

  get products() {
    return new ProductList(this.results?.products)
  }

  get prevPage() {
    return this.data.results?.prevPage || undefined
  }

  get nextPage() {
    return this.data.results?.nextPage || undefined
  }

  get hasControls() {
    return !!this.prevPage || !!this.nextPage
  }

  get page() {
    if (this.prevPage) return this.prevPage + 1
    if (this.nextPage) return this.nextPage - 1
    return 1
  }
}
