<template>
  <div class="auth-widget-login-by-social-approve">
    <base-form
      :title="t('AuthWidgetLoginBySocialApprove.title')"
      :message="t('AuthWidgetLoginBySocialApprove.message')"
      :loading="isPending"
      @submit="onSubmit"
    >
      <transition-group name="slide">
        <div v-if="!codeSent">
          <div class="row">
            <div class="column q-col-gutter-y-md full-width">
              <base-form-item
                :errors="v$.login.username.$errors"
                :label="t('inputs.phone.label')"
              >
                <base-input-phone
                  v-model="state.login.username"
                  :error="v$.login.username.$error"
                  username
                  @blur="v$.login.username.$touch"
                />
              </base-form-item>

              <base-form-item-preset
                v-model="state.login.firstName"
                preset="firstName"
                :error="v$.login.firstName.$error"
                :errors="v$.login.firstName.$errors"
                @blur="v$.login.firstName.$touch"
              />

              <base-form-item-preset
                v-model="state.login.lastName"
                preset="lastName"
                :error="v$.login.lastName.$error"
                :errors="v$.login.lastName.$errors"
                @blur="v$.login.lastName.$touch"
              />

              <base-form-item-preset
                v-model="state.login.email"
                preset="email"
                :error="v$.login.email.$error"
                :errors="v$.login.email.$errors"
                @blur="v$.login.email.$touch"
              />
            </div>
          </div>

          <base-form-item class="q-mt-lg">
            <app-button
              type="submit"
              color="primary"
              :loading="isPending"
              full-width
              :label="t('actions.continue')"
            />

            <template #after>
              <base-form-helper
                :label="t('questions.alreadyRegistered')"
                :action="t('actions.login')"
                @action="emit('login')"
              />
            </template>
          </base-form-item>
        </div>

        <div v-else>
          <base-form-item-code
            v-model="state.approve.phoneCode"
            :code-sent="codeSent"
            :error="v$.approve.phoneCode.$error"
            :errors="v$.approve.phoneCode.$errors"
            autofocus
            @blur="v$.approve.phoneCode.$touch"
            @complete="onSubmit"
          />

          <app-button
            class="q-mt-lg"
            type="submit"
            color="primary"
            :loading="isPending"
            full-width
            :label="t('actions.registration')"
          />
        </div>
      </transition-group>
    </base-form>
  </div>
</template>

<script lang="ts" setup>
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'
import type { LoginBySocialProviderPayload, LoginBySocialProviderApprovePayload } from 'src/api/modules/auth.module'
import BaseForm from 'src/components/base/BaseForm.vue'
import BaseFormItemPreset from 'src/components/base/BaseFormItemPreset.vue'
import BaseFormItem from 'src/components/base/BaseFormItem.vue'
import BaseFormHelper from 'src/components/base/BaseFormHelper.vue'
import BaseFormItemCode from 'src/components/base/BaseFormItemCode.vue'
import BaseInputPhone from 'src/components/base/BaseInputPhone.vue'
import {
  useLogin, useI18n, useNotify, useVuelidate, useVuelidateValidators,
  useApiAuthLoginBySocialProviderMutation,
useApiAuthLoginBySocialProviderApproveMutation
} from 'src/composables'
import { watch, computed, reactive, ref } from 'vue'

type LoginFields = Omit<LoginBySocialProviderPayload, 'code' | 'provider'>
type ApproveFields = Pick<LoginBySocialProviderApprovePayload, 'phoneCode'>

const props = defineProps<{
  socialProvider: SocialProvider
  socialProviderData: LoginSocialProviderResponse
}>()

const emit = defineEmits<{
  'success': []
  'login': []
}>()

const { t } = useI18n()

const { showError } = useNotify()

const validators = useVuelidateValidators()

const login = useLogin()

const loginMutation = useApiAuthLoginBySocialProviderMutation()

const loginApproveMutation = useApiAuthLoginBySocialProviderApproveMutation()

const codeSent = computed(() => loginMutation.data.value?.codeSent)

const state = reactive<{
  login: { [K in keyof Required<LoginFields>]: LoginFields[K] | undefined },
  approve: { [K in keyof Required<ApproveFields>]: ApproveFields[K] | undefined }
}>({
  login: {
    username: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
  },
  approve: {
    phoneCode: undefined,
  }
})

const rules = computed(() => {
  const { required, phone, email, phoneCode, firstName, lastName } = validators

  return {
    login: {
      username: { required, phone },
      firstName: { required, firstName },
      lastName: { required, lastName },
      email: { required, email },
    },
    approve: {
      phoneCode: { required, phoneCode }
    }
  }
})

const v$ = useVuelidate(rules, state, { $scope: false })

const isPending = ref(false)

watch(() => props.socialProviderData, (data) => {
  const { firstName, lastName, email } = data

  state.login.firstName = firstName
  state.login.lastName = lastName
  state.login.email = email
}, { immediate: true })

async function loginHandler() {
  const isValid = await v$.value.login.$validate()
  if (isPending.value || !isValid) return

  isPending.value = true

  const payload = {
    ...state.login,
    provider: props.socialProvider,
    code: props.socialProviderData.code
  } as LoginBySocialProviderPayload

  try {
    await loginMutation.mutateAsync(payload)
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function loginApproveHandler() {
  const isValid = await v$.value.$validate()
  if (isPending.value || !isValid) return

  isPending.value = true

  const payload = {
    ...state.login,
    provider: props.socialProvider,
    code: props.socialProviderData.code,
    phoneCode: state.approve.phoneCode
  } as LoginBySocialProviderApprovePayload

  try {
    const tokenSet = await loginApproveMutation.mutateAsync(payload)
    await login(tokenSet)
    emit('success')
  } catch (e) {
    showError(e)
  } finally {
    isPending.value = false
  }
}

async function onSubmit() {
  if (!codeSent.value) {
    await loginHandler()
  } else {
    await loginApproveHandler()
  }
}
</script>

<style lang="scss" scoped>

</style>
