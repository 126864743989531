<template>
  <form class="form__element" @submit.prevent="onSubmit">
    <app-input
      ref="inputRef"
      :model-value="modelValue"
      class="search-input"
      :placeholder="`${i18n.t('search.main.placeholder')}...`"
      :debounce="true"
      :loading="!!isFetching"
      :rounded="true"
      :maxlength="256"
      @update:model-value="onUpdate"
      @focus-native="emit('focus')"
    >
      <template #prepend>
        <ui-icon :icon="iconMagnifyingGlass" />
      </template>

      <template #append>
        <transition name="fade">
          <div v-if="!!modelValue" class="search-input__controls">
            <ui-icon :icon="iconXmark" class="cursor-pointer" @click="onClear" />

            <app-button
              type="button"
              round
              color="primary"
              :icon="iconMagnifyingGlass"
              class="search-input__btn"
              @click="onSubmit"
            />
          </div>
        </transition>
      </template>
    </app-input>
  </form>
</template>

<script setup lang="ts">
import iconXmark from 'assets/icons/light/xmark.svg?raw'
import iconMagnifyingGlass from 'assets/icons/light/magnifying-glass.svg?raw'
import UiIcon from 'src/components/ui/UiIcon.vue'
import type { AppInputExpose } from 'src/types/components'
import { useIsFetching } from '@tanstack/vue-query'
import { ref } from 'vue'
import RouterService from 'src/services/router.service'
import { useI18n } from 'src/composables/useI18n'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { queryKeys } from 'src/api'
import type { GetCategoryKnownParams } from 'src/models/catalog/category'

interface Props {
  modelValue: string
}

interface Emits {
  (e: 'update:modelValue', value: Props['modelValue']): void
  (e: 'focus'): void
  (e: 'clear'): void
  (e: 'submit', value: Props['modelValue']): void
}

const props = defineProps<Props>()

const emit = defineEmits<Emits>()

const i18n = useI18n()

const router = useRouter()

const inputRef = ref<AppInputExpose | null>(null)

const isFetching = useIsFetching({ queryKey: queryKeys.catalog.search._def })

const onUpdate = (value?: string | number | null) => {
  emit('update:modelValue', String(value ?? ''))
}

const onClear = () => {
  inputRef.value?.blur()
  emit('update:modelValue', '')
  emit('clear')
}

const onSubmit = async () => {
  inputRef.value?.blur()

  emit('submit', props.modelValue)

  if (!props.modelValue) return

  const query: Pick<GetCategoryKnownParams, 'searchText'> = {
    searchText: props.modelValue
  }

  const to = RouterService.getRouteLocation('search', { locale: i18n.locale.value }, query)

  await router.push(to)
}

watch(() => router.currentRoute.value.path, () => {
  onClear()
})
</script>

<style scoped lang="scss">
.search-input {
  --btn-ma: 0px;
  --btn-size: calc(var(--height) - var(--btn-ma) * 2);
  &__controls {
    margin-right: calc(var(--px) * -1) !important;
    display: flex;
    align-items: center;
    gap: var(--sm);
  }
  &__btn {
    --size: var(--btn-size) !important;
  }
}
</style>
