import type { ID, Nullable } from 'src/types'
import type { Opaque } from 'type-fest'
import type { NovaPoshtaCity, NovaPoshtaStreet, NovaPoshtaWarehouse } from 'src/models/novaPoshta'
import type { DeliveryTechnology } from 'src/models/delivery'

export type UserDeliveryAddress = {
  id: Opaque<ID, 'UserDeliveryAddressId'>
  city: NovaPoshtaCity['ref']
  technology: DeliveryTechnology['id']
  cityName: Nullable<string>
  street: Nullable<NovaPoshtaStreet['ref']>
  streetName: Nullable<string>
  house: Nullable<string>
  apartment: Nullable<string>
  novaPoshtaWarehouse: Nullable<NovaPoshtaWarehouse['ref']>
  novaPoshtaWarehouseName: Nullable<string>
}

export type UserDeliveryAddressInput = Pick<UserDeliveryAddress,
  'city' | 'street' | 'house' | 'apartment' | 'technology' | 'novaPoshtaWarehouse'
>

export class UserDeliveryAddressModel implements UserDeliveryAddress {
  id!: Opaque<ID, 'UserDeliveryAddressId'>
  city!: NovaPoshtaCity['ref']
  technology!: DeliveryTechnology['id']
  cityName: Nullable<string>
  street: Nullable<NovaPoshtaStreet['ref']>
  streetName: Nullable<string>
  house: Nullable<string>
  apartment: Nullable<string>
  novaPoshtaWarehouse: Nullable<NovaPoshtaWarehouse['ref']>
  novaPoshtaWarehouseName: Nullable<string>

  constructor(data: UserDeliveryAddress) {
    Object.assign(this, data)
  }

  get name() {
    const { novaPoshtaWarehouseName, cityName, streetName, house, apartment } = this

    const str = [cityName, novaPoshtaWarehouseName, streetName, house, apartment]
      .filter(item => !!item)
      .join(', ')

    return str
  }
}
