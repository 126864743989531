import type { FastRegistrationApprovePayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthFastRegistrationApproveMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: FastRegistrationApprovePayload) => api.auth.fastRegistrationApprove(payload)
  })
}
